import { useState, useContext } from 'react';
import { LanguageContext } from '../../context/LanguageContext';
import MainContext from '../../context/MainContext';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import LoadingButton from '../../components/loader';
import '../../style/auth/Register.css';

function AUTH_REGISTER_STEP3({ setRecoverStep, email, securityToken }) {
  const { language } = useContext(LanguageContext);
  const translations = language === 'en' ? enTranslations : ptTranslations;

  const [loading, setLoading] = useState(false);
  const [t_password, t_setPassword] = useState('');
  const [t_repeatPassword, t_setRepeatPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const validateBeforeContinuing = () => {
    const passwordConditions = {
      length: t_password.length >= 8,
      specialCharacter: /[!@#$%^&*(),.?":{}|<>]/.test(t_password),
      capitalLetter: /[A-Z]/.test(t_password),
      number: /\d/.test(t_password),
    };
  
    // Check if passwords match
    if (t_password !== t_repeatPassword) {
      setErrorMessage(translations.auth_recoverPassword_errorPasswordsDontMatch);
      setPasswordError(true);
      return;
    }
  
    // Check if passwords are empty
    if (t_password === '' || t_repeatPassword === '') {
      setErrorMessage(translations.auth_recoverPassword_errorPasswordCannotBeEmpty);
      setPasswordError(true);
      return;
    }
  
    // Validate password strength
    if (!passwordConditions.length || !passwordConditions.specialCharacter || !passwordConditions.capitalLetter || !passwordConditions.number) {
      let unmetConditions = [];
  
      if (!passwordConditions.length) unmetConditions.push('Password must be at least 8 characters long');
      if (!passwordConditions.capitalLetter) unmetConditions.push('Password must contain at least one capital letter');
      if (!passwordConditions.number) unmetConditions.push('Password must contain at least one number');      
  
      setErrorMessage(translations.auth_password_requirment);
      setPasswordError(true);
      return;
    }
  
    // Clear error and proceed
    setErrorMessage('');
    setPasswordError(false);
    
    setLoading(true);



    const baseURL = `${MainContext.apiURL}/api/v1/auth/users/recover/change-password`;

    const formData = new URLSearchParams({
      email: email.toLowerCase(),
      password: t_password,
      securityToken: securityToken
    });

    fetch(baseURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formData.toString(),
    })
      .then(response => {
        if (!response.ok) {
          setLoading(false);
          setErrorMessage(translations.auth_login_errorGenericMessage);
          return Promise.reject({ status: response.status, statusText: response.statusText });
        } else {
          setRecoverStep(4);
          return response.json();
        }
      })
      .catch(error => {
        setErrorMessage(translations.auth_login_errorGenericMessage);
        setLoading(false);
      });
  };

  return (
    <section className='auth-register-content-wrapper'>
      <div className='auth-register-content-main-title'>
        <h2>{translations.auth_recoverPassword_mainTitle}</h2>
      </div>

      <div className='auth-register-wrapper-form'>
        <div className='auth-register-form-row'>
          <div className='auth-register-form-input'>
            <span>{translations.auth_login_form_emailLabel}</span>
            <p>{email}</p>
          </div>
        </div>
        <div className='auth-register-form-row'>
          <div className='auth-register-form-input'>
            <span>{translations.auth_recoverPassword_passwordLabel}</span>
            <input type='password' placeholder={translations.auth_recoverPassword_passwordPlaceholder} onChange={(e) => t_setPassword(e.target.value)} className={passwordError ? ('auth-register-form-input-input-error') : ('auth-register-form-input-input')} />
          </div>
        </div>
        <div className='auth-register-form-row'>
          <div className='auth-register-form-input'>
            <span>{translations.auth_recoverPassword_repeatPasswordLabel}</span>
            <input type='password' placeholder={translations.auth_recoverPassword_repeatYourPasswordPlaceholder} onChange={(e) => t_setRepeatPassword(e.target.value)} className={passwordError ? ('auth-register-form-input-input-error') : ('auth-register-form-input-input')} />
          </div>
        </div>
      </div>

      {errorMessage !== '' && (
        <div className='auth-login-errorMessage'>
          <span>{errorMessage}</span>
        </div>
      )}

      <div className='auth-register-button'>
        <LoadingButton
          buttonText={translations.auth_recoverPassword_changePassword}
          loading={loading}
          actionOnClick={validateBeforeContinuing}
        />
      </div>
    </section>
  );
}

export default AUTH_REGISTER_STEP3;
