import { useContext } from 'react';
import { LanguageContext } from '../../../context/LanguageContext';
import enTranslations from '../../../translations/en.json'
import ptTranslations from '../../../translations/pt.json'
import '../../../style/public/landingPage/Section_Footer.css';

function SECTION_FOOTER() {
  const { language, toggleLanguage } = useContext(LanguageContext);
  const translations = language === 'en' ? enTranslations : ptTranslations;

  return (
    <section className='section-footer-wrapper'>

      <div className='section-footer-wrapper-container'>
        <div className='section-footer-title'>
          <h6 translate='no'>Match the Look</h6>
          <p>{translations.footer_companyDescription}</p>
        </div>

        <div className='section-footer-socials'>

          <a href='https://www.facebook.com/profile.php?id=61557251661638' target="_blank" rel="noreferrer"><img src='/socials/facebook-icon.png' alt='Facebook Socials' /></a>
          <a href='https://www.instagram.com/matchthelook_com' target="_blank" rel="noreferrer"><img src='/socials/instagram-icon.png' alt='Instagram Socials' /></a>
          <a href='https://www.tiktok.com/@matchthelook' target="_blank" rel="noreferrer"><img src='/socials/tiktok-icon.png' alt='Tik Tok Socials' /></a>
          <a href='https://www.linkedin.com/company/matchthelook/' target="_blank" rel="noreferrer"><img src='/socials/linkedin-icon.png' alt='Linkedin Socials' /></a>

        </div>

        <div className='section-footer-separator' />

        <div className='section-footer-links'>
          <div className='section-footer-links-language' onClick={() => language === 'en' ? (toggleLanguage('pt')) : (toggleLanguage('en'))}>
            <img src='/socials/globe-icon.png' alt='' />
            <span>{translations.footer_language}</span>
          </div>
          <a href='https://app.termly.io/policy-viewer/policy.html?policyUUID=a23a4bb3-efab-4579-bbdd-649795c08d66' target="_blank" rel="noreferrer">{translations.footer_termsConditions}</a>
          <a href='https://app.termly.io/policy-viewer/policy.html?policyUUID=869d8293-6736-4428-8b29-a627ec914268' target="_blank" rel="noreferrer">{translations.footer_privacyPolicy}</a>
          <a href='https://app.termly.io/policy-viewer/policy.html?policyUUID=3ff90b67-9738-4a2c-8db8-a65415316c51' target="_blank" rel="noreferrer">{translations.footer_cookiesPolicy}</a>
          <a className="termly-display-preferences">{translations.footer_consentPreferences}</a>
        </div>
      </div>

      <div className='section-footer-copyrights' translate='no'>
        <p>© 2024 - Match the Look - {translations.footer_allRightsReserved}</p>
      </div>
    </section>
  );
}

export default SECTION_FOOTER;
