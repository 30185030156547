import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { useAuth } from '../context/AuthContext';
import { LanguageContext } from '../context/LanguageContext';
import MainContext from '../context/MainContext';
import enTranslations from '../translations/en.json'
import ptTranslations from '../translations/pt.json'
import LoadingButton from './loader';
import './upgradePlusPrompt.css';

function UPGRADE_PLUS_PROMPT({ actionOnClick }) {
    const { getSessionInfo } = useAuth();
    const { language } = useContext(LanguageContext);
    const { localStoredEmail, localStoredUsername, localStoredSession } = getSessionInfo();
    const translations = language === 'en' ? enTranslations : ptTranslations;

    const isScrollLocked = true;

    useEffect(() => {
        if (isScrollLocked) {
            // Lock the scroll
            document.body.style.overflow = 'hidden';
            document.body.style.touchAction = 'none';

            // Smoothly scroll to the top when the scroll is locked
            window.scrollTo({
                top: 0,
                behavior: 'smooth',  // Smooth scrolling
            });
        } else {
            // Unlock the scroll
            document.body.style.overflow = '';
            document.body.style.touchAction = '';
        }

        return () => {
            document.body.style.overflow = '';
            document.body.style.touchAction = '';
        };
    }, [isScrollLocked]);

    const [creatingPaymentSession, setCreatingPaymentSession] = useState(false);

    const createCheckoutSession = async () => {
        setCreatingPaymentSession(true);
        const baseURL = `${MainContext.apiURL}/api/v1/private/users/pay/subscription`;
    
        const formData = new URLSearchParams({
          email: localStoredEmail,
          username: localStoredUsername,
          page: 'none',
          language: language,
          plan: 'plus'
        });
    
        fetch(baseURL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'email': localStoredEmail,
            'username': localStoredUsername,
            'session': localStoredSession
          },
          credentials: 'include',
          body: formData.toString(),
        })
          .then(response => {
            if (!response.ok) {
              setCreatingPaymentSession(false);
              return Promise.reject({ status: response.status, statusText: response.statusText });
            } else {
              return response.json();
            }
          })
          .then(data => {
            const currentTimestampUTC = new Date().toISOString();
    
            const paymentIntentObject = {
              timestamp: currentTimestampUTC
            };
    
            localStorage.setItem('subscription-payment-intent', JSON.stringify(paymentIntentObject));
            localStorage.setItem('subscription-payment-intent-plan', 'plus');
    
            setCreatingPaymentSession(false);
    
            window.location.href = data.session;
          })
          .catch(error => {
            console.error('Error activating account:', error);
            setCreatingPaymentSession(false);
          });
      };

    return (
        <div className='upgrade-plan-prompt-background'>
            <div className='upgrade-plan-prompt-background-container'>
                <div className='upgrade-plan-prompt-title'>
                    <div className='upgrade-plan-prompt-title-title'>
                    <img src='/private/upgrade-icon.png' alt='' className='pulse' />
                        <h2>{translations.upgradePrompt_mainTitle}</h2>
                    </div>
                    <span>{translations.upgradePrompt_description}</span>
                </div>

                <div className='upgrade-plan-prompt-close'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" onClick={() => actionOnClick(false)}>
                        <path d="M19.0005 4.99988L5.00049 18.9999M5.00049 4.99988L19.0005 18.9999" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>

                <div className='upgrade-prompt-image'>
                    <img src='/private/rocket.png' alt='' className='pulse' />
                </div>

                <div className='upgrade-plan-prompt-description'>
                    <p>{translations.upgradePrompt_descriptionBulletPlusPlan}</p>
                    <li>{translations.upgradePrompt_advantagePlus01}</li>
                    <li>{translations.upgradePrompt_advantagePlus02}</li>
                    <li>{translations.upgradePrompt_advantagePlus03}</li>
                    <li>{translations.upgradePrompt_advantagePlus04}</li>
                    <li>{translations.upgradePrompt_advantagePlus05}</li>
                </div>

                <LoadingButton
                    buttonText={translations.upgradePrompt_updateNowButton}
                    loading={creatingPaymentSession}
                    actionOnClick={createCheckoutSession}
                />
                <div className='upgrade-plan-prompt-cost'>
                    <span>{translations.upgradePrompt_priceWithoutLoyality}</span>
                </div>
            </div>
        </div>
    );
}

export default UPGRADE_PLUS_PROMPT;