import React, { useState, useRef, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import MainContext from '../../context/MainContext'
import { useAuth } from '../../context/AuthContext';
import { LanguageContext } from '../../context/LanguageContext';
import IMAGE from './newShare/Image'
import PROPERTIES from './newShare/Properties'
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import '../../style/private/NewShare.css'

function NEWSHARE({ setUploadingInformation }) {
    const location = useLocation();
    const { getSessionInfo, logout } = useAuth();
    const { language } = useContext(LanguageContext);
    const { localStoredEmail, localStoredUsername, localStoredSession } = getSessionInfo();
    const translations = language === 'en' ? enTranslations : ptTranslations;

    const [pageType, setPageType] = useState('');
    const [editImage, setEditImage] = useState('');
    const [editImageExtension, setEditImageExtension] = useState('');

    const urlShare = location.state?.shareData;
    const shareCache = localStorage.getItem(`share_${location.state?.shareData.share}`);

    useEffect(() => {
        const pathSegment = location.pathname.split('/')[4];
        setPageType(pathSegment);

        if (urlShare) {

            if (!shareCache) {
                localStorage.setItem(`share_${location.state?.shareData.share}`, JSON.stringify(location.state?.shareData));
            }

            setEditImage(location.state?.shareData.share);
            setEditImageExtension(location.state?.shareData.extension);

            if (location.state?.shareData.gender === 'Man' || location.state?.shareData.gender === 'Masculino' || location.state?.shareData.gender === 'Homem') {
                setGender('man')
            } else {
                setGender('woman');
            }
            if (location.state?.shareData.style === 'Casual') {
                setStyle('casual');
            } else if (location.state?.shareData.style === 'Formal') {
                setStyle('formal');
            } else if (location.state?.shareData.style === 'Sporty' || location.state?.shareData.style === 'sporty' || location.state?.shareData.style === 'Desportivo') {
                setStyle('sporty');
            } else if (location.state?.shareData.style === 'Vintage') {
                setStyle('vintage');
            } else if (location.state?.shareData.style === 'Elegant' || location.state?.shareData.style === 'Elegante') {
                setStyle('elegant');
            }

            setOtherOptions(location.state?.shareData.options);
            setClothing(location.state?.shareData.clothes);
            setAccessories(location.state?.shareData.accessories);
            setFootwear(location.state?.shareData.footwear);
            return;
        }

    }, [location.pathname, urlShare]);

    // eslint-disable-next-line
    const [image, setImage] = useState(null);
    const imageRef = useRef(null);

    const [gender, setGender] = useState('');
    const [style, setStyle] = useState('');
    const [otherOptions, setOtherOptions] = useState({});
    const [newShareStep, setNewShareStep] = useState(1);

    const allowToContinue = pageType === 'new'
        ? gender && style && image
        : gender && style;

    const [clothing, setClothing] = useState({
        count: 0,
        items: {
            jacket: '',
            blazer: '',
            vest: '',
            sweater: '',
            blouse: '',
            dress: '',
            polo: '',
            shirt: '',
            tshirt: '',
            pants: '',
            jeans: '',
            shorts: '',
            skirt: '',
            pijama: '',
            swimwear: '',
            boxers: '',
            bra: '',
            panties: '',
            socks: '',
            other: ''
        }
    });

    const [accessories, setAccessories] = useState({
        count: 0,
        items: {
            cap: '',
            hat: '',
            gloves: '',
            scarf: '',
            watch: '',
            belt: '',
            handbag: '',
            necklace: '',
            ring: '',
            earrings: '',
            bag: '',
            bagpack: '',
            tie: '',
            bowtie: '',
            bracelet: '',
            wallet: '',
            glasses: '',
            other: ''
        }
    });

    const [footwear, setFootwear] = useState({
        count: 0,
        items: {
            shoes: '',
            sneakers: '',
            sandals: '',
            mocassins: '',
            boots: '',
            ankleboots: '',
            highheels: '',
            elegantshoes: '',
            flipflops: '',
            slippers: '',
            other: ''
        }
    });

    const allowToContinue2 = allowToContinue && (clothing.count > 0 || accessories.count > 0 || footwear.count > 0);

    // Receives the Image from the Image React Fragment
    const handleImageSelection = (selectedImage) => {
        setImage(selectedImage);
    };

    const uploadPictureToAzure = async () => {
        setUploadingInformation(true);

        if (pageType === 'new') {
            newShare();
        } else if (pageType === 'edit') {
            updateShare();
        }

    };

    const newShare = async () => {
        let response;

        if (imageRef.current) {
            response = await imageRef.current.submitCroppedImage();
        }

        if (response.ok) {
            const blob = await response.blob();

            const baseURL = `${MainContext.apiURL}/api/v1/private/shares/create/share`;

            const formData = new FormData();
            formData.append('image', blob);
            formData.append('username', localStoredUsername);
            formData.append('gender', gender);
            formData.append('style', style);
            formData.append('clothes', JSON.stringify(clothing));
            formData.append('accessories', JSON.stringify(accessories));
            formData.append('footwear', JSON.stringify(footwear));
            formData.append('otherOptions', JSON.stringify(otherOptions));

            fetch(baseURL, {
                method: 'POST',
                headers: {
                    'email': localStoredEmail,
                    'username': localStoredUsername,
                    'session': localStoredSession
                },
                credentials: 'include',
                body: formData,
            })
                .then(response => {
                    if (response.status === 403) {
                        setUploadingInformation(false);
                        logout();
                    } else if (!response.ok) {
                        setUploadingInformation(false);
                        return Promise.reject({ status: response.status, statusText: response.statusText });
                    } else {
                        return response.json();
                    }
                })
                .then(data => {
                    const newShare = { picture: data.picture, extension: '.jpg' };

                    const savedShares = localStorage.getItem('profile-shares');
                    let sharesArray = [];

                    // Ensure the parsed data is an array, or initialize it as an empty array
                    if (savedShares) {
                        try {
                            const parsedShares = JSON.parse(savedShares);
                            sharesArray = Array.isArray(parsedShares) ? parsedShares : [];
                        } catch (error) {
                            console.error('Error parsing savedShares:', error);
                            sharesArray = []; // Initialize as an empty array if parsing fails
                        }
                    } else {
                        localStorage.setItem('profile-shares-temp', true);
                    }

                    sharesArray.unshift(newShare);

                    localStorage.setItem('profile-shares', JSON.stringify(sharesArray));

                    setUploadingInformation(false);

                    window.location.href = `/${language}/${localStoredUsername}/share/${data.picture}`;

                })
                .catch(error => {
                    console.error('Error creating account:', error);
                });
        }
    }

    const updateShare = () => {

        const baseURL = `${MainContext.apiURL}/api/v1/private/shares/update/share`;

        const parsedShares = shareCache ? JSON.parse(shareCache) : null;

        const formData = new URLSearchParams({
            picture: editImage,
            gender: gender,
            style: style,
            clothes: JSON.stringify(clothing),
            accessories: JSON.stringify(accessories),
            footwear: JSON.stringify(footwear),
            options: JSON.stringify(otherOptions),
            parsedShares: JSON.stringify(parsedShares),
            language: language
        });

        fetch(baseURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'email': localStoredEmail,
                'username': localStoredUsername,
                'session': localStoredSession
            },
            credentials: 'include',
            body: formData.toString(),
        })
            .then(response => {
                if (!response.ok) {
                    setUploadingInformation(false);
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    const muralShares = JSON.parse(localStorage.getItem('private-mural-shares'));
                    if (muralShares) {
                        // Find the share and update its data
                        const updatedMuralShares = muralShares.map(entry => {
                            if (entry.share === editImage) {
                                return {
                                    ...entry,
                                    gender: gender, // Replace with the updated gender value
                                    style: style, // Replace with the updated style value
                                    options: otherOptions, // Replace with updated options object
                                    clothes: clothing, // Replace with updated clothes object
                                    accessories: accessories, // Replace with updated accessories object
                                    footwear: footwear // Replace with updated footwear object
                                };
                            }
                            return entry; // Return unchanged entry if it doesn't match
                        });

                        // Save updated muralShares back to localStorage
                        localStorage.setItem('private-mural-shares', JSON.stringify(updatedMuralShares));
                    }

                    // Remove the temporary share key
                    localStorage.removeItem(`share_${editImage}`);
                    setUploadingInformation(false);

                    // Redirect to the updated share page
                    window.location.href = `/${language}/${localStoredUsername}/share/${editImage}`;

                    return response.json();
                }

            })
            .then(data => {

            })
            .catch(error => {
                console.error('Error updating share:', error);
                setUploadingInformation(false);
            });
    };

    return (
        <section className='newshare-wrapper'>
            <div className='newshare-images-wrapper'>
                {pageType === 'new' && (
                    <IMAGE
                        onImageSelect={handleImageSelection}
                        ref={imageRef}
                        translations={translations}
                    />
                )}

                {pageType === 'edit' && (
                    <div className='newshare-image-edit'>
                        <img src={`${MainContext.storageClothesURL}${editImage}${editImageExtension}`} alt='' />
                    </div>
                )}
            </div>
            <div className='newshare-references-wrapper'>
                <PROPERTIES
                    gender={gender}
                    setGender={setGender}
                    style={style}
                    setStyle={setStyle}
                    otherOptions={otherOptions}
                    setOtherOptions={setOtherOptions}
                    allowToContinue={allowToContinue}
                    allowToContinue2={allowToContinue2}
                    clothing={clothing}
                    setClothing={setClothing}
                    accessories={accessories}
                    setAccessories={setAccessories}
                    footwear={footwear}
                    setFootwear={setFootwear}
                    language={language}
                    translations={translations}
                    submitShare={uploadPictureToAzure}
                    newShareStep={newShareStep}
                    setNewShareStep={setNewShareStep}
                    MainContext={MainContext}
                />
            </div>
        </section>
    );
}

export default NEWSHARE;