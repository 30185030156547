import { useState, useContext } from 'react';
import { LanguageContext } from '../../context/LanguageContext';
import MainContext from '../../context/MainContext';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import LoadingButton from '../../components/loader';
import '../../style/auth/Register.css';

function AUTH_REGISTER_STEP1({ setRegisterStep, email, setSecurityToken }) {
  const { language } = useContext(LanguageContext);
  const translations = language === 'en' ? enTranslations : ptTranslations;

  const [loading, setLoading] = useState(false);
  const [securityCode, setSecurityCode] = useState('');
  const [securityCodeError, setSecurityCodeError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const validateSecurityCode = () => {

    if (securityCode.length !== 6) {
      setSecurityCodeError(true);
      setErrorMessage(translations.auth_recoverPassword_errorMessage_insertOTPWith6Digits);
      return;
    }

    setSecurityCodeError(false);
    setErrorMessage('');
    setLoading(true);

    const baseURL = `${MainContext.apiURL}/api/v1/auth/users/register/validate-token`;

    const formData = new URLSearchParams({
      email: email.toLowerCase(),
      securityToken: securityCode,
      language: language
    });

    fetch(baseURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formData.toString(),
    })
      .then(response => {
        if (!response.ok) {
          setLoading(false);
          setErrorMessage(translations.auth_login_errorGenericMessage);
          return Promise.reject({ status: response.status, statusText: response.statusText });
        } else {
          setSecurityToken(securityCode);
          setRegisterStep(3);
          return response.json();
        }
      })
      .then(data => {
      })
      .catch(error => {
        setErrorMessage(translations.auth_login_errorGenericMessage);
        setLoading(false);
      });

  }

  return (
    <section className='auth-register-content-wrapper'>
      <div className='auth-register-content-main-title'>
        <h2>{translations.auth_register_mainTitle}</h2>
      </div>

      <div className='auth-register-wrapper-form'>
        <div className='auth-register-form-row'>
          <div className='auth-register-form-input'>
            <span>{translations.auth_login_form_emailLabel}</span>
            <p>{email}</p>
          </div>
        </div>
        <div className='auth-register-form-row'>
          <div className='auth-register-form-input'>
            <span>{translations.auth_recoverPassword_securityCodeLabel}</span>
            <input
              type='text'
              placeholder={translations.auth_recoverPassword_securityCodePlaceholder}
              pattern="\d{6}"
              maxLength="6"
              inputMode="numeric"
              className={securityCodeError ? ('auth-register-form-input-input-error') : ('auth-register-form-input-input')}
              onInput={(e) => setSecurityCode(e.target.value = e.target.value.replace(/\D/g, '').slice(0, 6))}
            />
          </div>
        </div>
      </div>

      {errorMessage !== '' && (
        <div className='auth-login-errorMessage'>
          <span>{errorMessage}</span>
        </div>
      )}

      <div className='auth-register-button'>
        <LoadingButton
          buttonText={translations.auth_recoverPassword_buttonValidateCode}
          loading={loading}
          actionOnClick={validateSecurityCode}
        />
      </div>
    </section>
  );
}

export default AUTH_REGISTER_STEP1;
