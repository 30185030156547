import React, { useState, useEffect } from 'react';
import NewShareOptions from '../../../components/newShareOptions';
import LoadingButton from '../../../components/loader';
import '../../../style/private/newShare/propertiesList.css'

function PropertiesNewShare({ gender, setGender, style, setStyle, otherOptions, setOtherOptions, allowToContinue, loading, setNewShareStep, translations, language, MainContext }) {


    const [otherOptionsOpen, setOtherOptionsOpen] = useState(false);
    const [isScrollLocked, setIsScrollLocked] = useState(false);

    const [targetCountry, setTargetCountry] = useState(otherOptions?.country || 'all');
    const [isUserModified, setIsUserModified] = useState(false);
    const [countryOptions, setCountryOptions] = useState([]);

    const openOrCloseOptions = () => {
        setOtherOptionsOpen(!otherOptionsOpen);
        setIsScrollLocked(!isScrollLocked);
    }

    const saveOtherOptions = (data) => {
        setOtherOptions(prevOptions => ({ ...prevOptions, ...data }));
        openOrCloseOptions();
    };

    const moveToStep2 = () => {
        setOtherOptions(prevOptions => ({ ...prevOptions, country: targetCountry || 'all' }));
        setNewShareStep(2);
    }

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        getCountryList();
    }, []);
    /* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (!isUserModified) {
            setTargetCountry(otherOptions.country);
        }
    }, [otherOptions, isUserModified]);

    const handleUserChange = (newCountry) => {
        setIsUserModified(true);
        setTargetCountry(newCountry);
    };

    const getCountryList = () => {
        const data = new URLSearchParams({
            language: language
        });

        fetch(`${MainContext.apiURL}/api/v1/auth/users/register/fetch-countries?${data}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (!response.ok) {
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                setCountryOptions(data.countries);
            })
            .catch(error => {

            });
    };

    return (
        <section className='newshare-properties-properties-list-wrapper'>
            <div className='newshare-properties-properties-list-title'>
                <h2>{translations.private_newShare_sharePropertiesTitle}</h2>
            </div>
            <div className='newshare-properties-properties-list-options-wrapper'>
                <div className='newshare-properties-properties-list-options'>
                    <h3>{translations.private_newShare_targetGender}</h3>
                    <div className='newshare-properties-properties-list-options-container'>
                        <button className={gender === 'man' ? ('newshare-properties-properties-list-options-active') : ('newshare-properties-properties-list-options-disabled')} onClick={() => setGender('man')}>{translations.private_newShare_man}</button>
                        <button className={gender === 'woman' ? ('newshare-properties-properties-list-options-active') : ('newshare-properties-properties-list-options-disabled')} onClick={() => setGender('woman')}>{translations.private_newShare_Woman}</button>
                    </div>
                </div>

                <div className='newshare-properties-properties-list-options'>
                    <h3>{translations.private_newShare_targetStyle}</h3>
                    <div className='newshare-properties-properties-list-options-container'>
                        <button className={style === 'casual' ? ('newshare-properties-properties-list-options-active') : ('newshare-properties-properties-list-options-disabled')} onClick={() => setStyle('casual')}>{translations.private_newShare_styleCasual}</button>
                        <button className={style === 'formal' ? ('newshare-properties-properties-list-options-active') : ('newshare-properties-properties-list-options-disabled')} onClick={() => setStyle('formal')}>{translations.private_newShare_styleFormal}</button>
                        <button className={style === 'sporty' ? ('newshare-properties-properties-list-options-active') : ('newshare-properties-properties-list-options-disabled')} onClick={() => setStyle('sporty')}>{translations.private_newShare_styleSporty}</button>
                        <button className={style === 'elegant' ? ('newshare-properties-properties-list-options-active') : ('newshare-properties-properties-list-options-disabled')} onClick={() => setStyle('elegant')}>{translations.private_newShare_styleElegant}</button>
                        <button className={style === 'vintage' ? ('newshare-properties-properties-list-options-active') : ('newshare-properties-properties-list-options-disabled')} onClick={() => setStyle('vintage')}>{translations.private_newShare_styleVintage}</button>
                    </div>
                </div>

                <div className='newshare-properties-properties-list-options'>
                    <h3>{translations.private_newShare_targetCountry} <span>({translations.private_newShare_optional})</span></h3>
                    <div className='newshare-properties-properties-list-options-container'>
                        <select defaultValue={targetCountry} value={targetCountry} onChange={(e) => handleUserChange(e.target.value)}>
                            <option value='all'>{translations.auth_register_countryPlaceholder}</option>
                            {countryOptions.map((nationality, index) => (
                                <option key={index} value={nationality.value}>
                                    {nationality.label}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className='newshare-properties-properties-list-options'>
                    <h3>{translations.private_newShare_otherOptions} <span>({translations.private_newShare_optional})</span></h3>
                    <div className='newshare-properties-properties-list-options-container'>
                        <button className='newshare-properties-properties-list-options-otherOptions' onClick={() => setOtherOptionsOpen(true)}>{translations.private_newShare_openOtherOptions}</button>
                    </div>
                </div>
            </div>

            <div className='newshare-properties-properties-action-button'>
                {allowToContinue ? (
                    <LoadingButton
                        buttonText={translations.private_newShare_nextStep}
                        loading={loading}
                        actionOnClick={moveToStep2}
                    />
                ) : (
                    <button disabled>{translations.private_newShare_nextStep}</button>
                )}
            </div>

            {otherOptionsOpen && (
                <NewShareOptions
                    isScrollLocked={true}
                    actionOnClick={saveOtherOptions}
                    otherOptions={otherOptions}
                    openOrCloseOptions={openOrCloseOptions}
                />
            )}

        </section >
    );
}

export default PropertiesNewShare;