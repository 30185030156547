import React, { createContext, useContext, useState, useEffect } from 'react';
import MainContext from '../context/MainContext';

const AuthContext = createContext();

const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const isValidUsername = (username) => {
  return username.trim() !== '';
};

const isValidSessionToken = (sessionToken) => {
  return typeof sessionToken === 'string' && sessionToken.length >= 20;
};

export const AuthProvider = ({ children }) => {
  const localStoredEmail = localStorage.getItem('email');
  const localStoredUsername = localStorage.getItem('username');
  const localStoredSessionToken = localStorage.getItem('session');

  const userAuthenticated = Boolean(
    isValidEmail(localStoredEmail) &&
    isValidUsername(localStoredUsername) &&
    isValidSessionToken(localStoredSessionToken)
  );

  const [isAuthenticated, setIsAuthenticated] = useState(userAuthenticated);

  useEffect(() => {
    const handleStorageChange = () => {
      const email = localStorage.getItem('email');
      const username = localStorage.getItem('username');
      const sessionToken = localStorage.getItem('session');
      setIsAuthenticated(
        Boolean(
          isValidEmail(email) &&
          isValidUsername(username) &&
          isValidSessionToken(sessionToken)
        )
      );
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  function login(name, surname, email, username, session, picture, gender, birthday, country, socials, referralcode, subscription, displayName) {
    if (isValidEmail(email) && isValidUsername(username) && isValidSessionToken(session)) {

      localStorage.clear();

      localStorage.setItem('name', name);
      localStorage.setItem('surname', surname);
      localStorage.setItem('email', email);
      localStorage.setItem('username', username);
      localStorage.setItem('session', session);
      localStorage.setItem('picture', picture);
      localStorage.setItem('gender', gender);
      localStorage.setItem('private-mural-shares-filter-gender', gender);
      localStorage.setItem('birthday', birthday);
      localStorage.setItem('country', country);
      localStorage.setItem('socials', JSON.stringify(socials));
      localStorage.setItem('referralcode', referralcode);
      localStorage.setItem('subscription', subscription);
      localStorage.setItem('displayName', displayName);
      setIsAuthenticated(true);
    } else {
      console.error('Invalid login credentials');
    }
  }

  function getSessionInfo() {
    return {
      localStoredName: localStorage.getItem('name'),
      localStoredSurname: localStorage.getItem('surname'),
      localStoredEmail: localStorage.getItem('email'),
      localStoredUsername: localStorage.getItem('username'),
      localStoredSession: localStorage.getItem('session'),
      localStoredProfilePicture: localStorage.getItem('picture'),
      localStoredGender: localStorage.getItem('gender'),
      localStoredBirthday: localStorage.getItem('birthday'),
      localStoredCountry: localStorage.getItem('country'),
      localStoredSocials: localStorage.getItem('socials'),
      localStoredReferralCode: localStorage.getItem('referralcode'),
      localStoredSubscription: localStorage.getItem('subscription'),
      localStoredLanguage: localStorage.getItem('language'),
      localStoredDisplayName: localStorage.getItem('displayName'),
    };
  }

  function logout() {

    const formData = new URLSearchParams({
      email: localStorage.getItem('email'),
      session: localStorage.getItem('session'),
    });

    fetch(`${MainContext.apiURL}/api/v1/auth/users/logout`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formData.toString(),
    })
      .then(response => {
        const language = localStorage.getItem('language');

        if(language === 'pt') {
          localStorage.clear();
          setIsAuthenticated(false);
          window.location.href = `/pt`;
        } else if(language === 'en') {
          localStorage.clear();
          setIsAuthenticated(false);
          window.location.href = `/en`;
        } else {
          localStorage.clear();
          setIsAuthenticated(false);
          window.location.href = `/`;
        }

      })
      .catch(error => {
        console.log(error);

        const language = localStorage.getItem('language');

        if(language === 'pt') {
          localStorage.clear();
          setIsAuthenticated(false);
          window.location.href = `/pt`;
        } else if(language === 'en') {
          localStorage.clear();
          setIsAuthenticated(false);
          window.location.href = `/en`;
        } else {
          localStorage.clear();
          setIsAuthenticated(false);
          window.location.href = `/`;
        }

      });
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, getSessionInfo }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};