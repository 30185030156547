import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { LanguageContext } from '../../context/LanguageContext';
import MainContext from '../../context/MainContext';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import LoadingButton from '../../components/loader';
import LoadingWithText from '../../components/loadingWithText';
import '../../style/public/UserProfile.css';

function USER_PROFILE() {
    const { username } = useParams();
    const { getSessionInfo } = useAuth();
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;
    const { localStoredUsername, localStoredProfilePicture } = getSessionInfo();

    const [imageLoadingFromStorage, setImageLoadingFromStorage] = useState(true);

    const [userProfile, setUserProfile] = useState([]);

    const [generalLoading, setGeneralLoading] = useState(true);
    const [loadingMoreShares, setLoadingMoreShares] = useState(false);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        setGeneralLoading(true);

        loadUserShares();

        return;
        const savedShares = localStorage.getItem(`user-profile_${username}`);
        const profileSharesEnd = localStorage.getItem(`user-profile_${username}_end`) === 'true';

        if (savedShares) {
            // If shares exist in local storage, use them
            setUserProfile(JSON.parse(savedShares));

            // Check the profileSharesEnd value and set loadingMoreShares accordingly
            if (profileSharesEnd) {
                setLoadingMoreShares(true);
            } else {
                setLoadingMoreShares(false);
            }
            
            setGeneralLoading(false);
        } else {
            // Otherwise, load from API
            loadUserShares();
        }
    }, []);
    /* eslint-disable react-hooks/exhaustive-deps */

    async function loadUserShares() {
        const baseURL = `${MainContext.apiURL}/api/v1/public/users/profile/shares`;

        const data = new URLSearchParams({
            username: username,
        });

        fetch(`${baseURL}?${data}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        })
            .then(response => {
                if (response.status === 404) {
                    //User don't exist, create page
                } else if (!response.ok) {
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                setUserProfile({
                    user: data.shares.user[0],
                    shares: data.shares.shares
                });

                localStorage.setItem(`user-profile_${username}`, JSON.stringify(data));

                if (!data.end) {
                    setLoadingMoreShares(true);
                }

                setGeneralLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoadingMoreShares(false);
                setGeneralLoading(false);
            });
    };

    return (
        <section className='user-public-profile-wrapper'>

            {!generalLoading ? (
                <>
                    <div className='user-public-profile-user-info'>

                        {localStoredProfilePicture ? (
                            <img src={`${MainContext.profilePictureURL}${userProfile.user.profilepicture}`} alt='User Profile' />
                        ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <circle cx="12" cy="12" r="10" stroke="currentColor" stroke-width="1.5" />
                                <path d="M7.5 17C9.8317 14.5578 14.1432 14.4428 16.5 17M14.4951 9.5C14.4951 10.8807 13.3742 12 11.9915 12C10.6089 12 9.48797 10.8807 9.48797 9.5C9.48797 8.11929 10.6089 7 11.9915 7C13.3742 7 14.4951 8.11929 14.4951 9.5Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                            </svg>
                        )}



                        <h2>{userProfile.user.displayname}</h2>

                        <span>@{username}</span>
                    </div>

                    <div className='user-public-profile-user-socials'>
                        {localStoredUsername === username && (
                            <>
                                <div className='user-public-profile-user-socials-edit-profile'>
                                    <a href={`/${language}/account/${localStoredUsername}/my-settings`}><button>{translations.userProfile_editProfile}</button></a>
                                </div>
                            </>
                        )}
                        {
                            // Check if there is at least one social link available
                            (userProfile.user.socials?.facebook ||
                                userProfile.user.socials?.instagram ||
                                userProfile.user.socials?.tiktok ||
                                userProfile.user.socials?.linkedin ||
                                userProfile.user.socials?.website) && (
                                <>
                                    <div className='user-public-profile-user-socials-separator'>
                                        <span>|</span>
                                    </div>

                                    <div className={localStoredUsername === username ? 'user-private-profile-user-socials-links' : 'user-public-profile-user-socials-links'}>
                                        {userProfile.user.socials?.facebook && (
                                            <a href={userProfile.user.socials?.facebook.startsWith('http') ? userProfile.user.socials?.facebook : `https://${userProfile.user.socials?.facebook}`} target="_blank" rel="noopener noreferrer">
                                                <img src='/socials/facebook-icon.png' alt='Facebook' />
                                            </a>
                                        )}

                                        {userProfile.user.socials?.instagram && (
                                            <a href={userProfile.user.socials?.instagram.startsWith('http') ? userProfile.user.socials?.instagram : `https://${userProfile.user.socials?.instagram}`} target="_blank" rel="noopener noreferrer">
                                                <img src='/socials/instagram-icon.png' alt='Instagram' />
                                            </a>
                                        )}

                                        {userProfile.user.socials?.tiktok && (
                                            <a href={userProfile.user.socials?.tiktok.startsWith('http') ? userProfile.user.socials?.tiktok : `https://${userProfile.user.socials?.tiktok}`} target="_blank" rel="noopener noreferrer">
                                                <img src='/socials/tiktok-icon.png' alt='TikTok' />
                                            </a>
                                        )}

                                        {userProfile.user.socials?.linkedin && (
                                            <a href={userProfile.user.socials?.linkedin.startsWith('http') ? userProfile.user.socials?.linkedin : `https://${userProfile.user.socials?.linkedin}`} target="_blank" rel="noopener noreferrer">
                                                <img src='/socials/linkedin-icon.png' alt='LinkedIn' />
                                            </a>
                                        )}

                                        {userProfile.user.socials?.website && (
                                            <a href={userProfile.user.socials?.website.startsWith('http') ? userProfile.user.socials?.website : `https://${userProfile.user.socials?.website}`} target="_blank" rel="noopener noreferrer">
                                                <img src='/socials/globe-icon.png' alt='Website' />
                                            </a>
                                        )}
                                    </div>
                                </>

                            )

                        }


                    </div>

                    <div className='user-public-profile-shares'>
                        <div className={'grid-shares'}>
                            {Array.isArray(userProfile.shares) && userProfile.shares.length > 0 ? (
                                userProfile.shares.map((share) => (
                                    <div className={'grid-shares-container'} key={share.share}>
                                        <div className='grid-shares-container-share'>
                                            {imageLoadingFromStorage && (
                                                <div className='grid-image-loader-container'>
                                                    <div className="grid-image-loader"></div>
                                                </div>
                                            )}

                                            <a href={`/${language}/${localStoredUsername}/share/${share.share}`}>
                                                <img
                                                    src={`${MainContext.storageClothesURL}${share.share}${share.extension}`}
                                                    alt={`Share-${share.share}`}
                                                    onLoad={() => setImageLoadingFromStorage(false)}
                                                    style={{ display: imageLoadingFromStorage ? 'none' : 'block' }}
                                                />
                                            </a>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div>No shares available</div>
                            )}

                        </div>
                    </div>

                    {loadingMoreShares && (
                        <div className='user-public-profile-shares-load-more'>
                            <LoadingButton
                                buttonText={translations.userProfile_loadMore}
                            />
                        </div>
                    )}
                </>
            ) : (
                <LoadingWithText />
            )}

        </section>
    );
}

export default USER_PROFILE;