import { useContext } from 'react';
import { LanguageContext } from '../../../context/LanguageContext';
import enTranslations from '../../../translations/en.json'
import ptTranslations from '../../../translations/pt.json'
import Pricing from '../../../components/pricing';
import '../../../style/public/landingPage/Section_Pricing.css';

function SECTION_PRICING() {
  const { language } = useContext(LanguageContext);
  const translations = language === 'en' ? enTranslations : ptTranslations;

  const handleClickStarted = (plan) => {
    if (plan === 'essentials') {
      window.location.href = `/${language}/register/essentials`;
    } else if (plan === 'plus') {
      window.location.href = `/${language}/register/plus`;
    }
  };

  return (
    <section className='section-pricing-wrapper'>

      <div className='section-pricing-wrapper-container'>
        <h2>{translations.landingPage_sectionPricing_mainTitle_choosePlan}</h2>
      </div>

      <Pricing
        subActive={'none'}
        actionOnClick={handleClickStarted}
        source={'landingPage'}
      />

    </section>
  );
}

export default SECTION_PRICING;
