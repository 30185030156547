import { useContext } from 'react';
import { LanguageContext } from '../context/LanguageContext';
import enTranslations from '../translations/en.json'
import ptTranslations from '../translations/pt.json'
import './uploadingWithText.css';

function LOADING_WITH_TEXT() {
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;

    return (
        <div className='loadingWithText-container'>
            <div class="loadingWithText-spinnerContainer">
                <div class="loadingWithText-spinner"></div>
                <div class="loadingWithText-loader">
                    <p>{translations.components_uploadingWithText_uploading}</p>
                    <div class="loadingWithText-words">
                        <span class="loadingWithText-word">{translations.components_uploadingWithText_image}</span>
                        <span class="loadingWithText-word">{translations.components_uploadingWithText_image}</span>
                        <span class="loadingWithText-word">{translations.components_uploadingWithText_references}</span>
                        <span class="loadingWithText-word">{translations.components_uploadingWithText_inspiration}</span>
                        <span class="loadingWithText-word">{translations.components_uploadingWithText_outfit}</span>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default LOADING_WITH_TEXT;