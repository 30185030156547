import './loader.css';

function LOADING_BUTTON({ buttonText, loading, actionOnClick}) {

    return (
        <div className='button-loading'>
            <button disabled={loading} onClick={actionOnClick}>
                {loading ? (
                    <span className="loader"></span>
                ) : (
                    buttonText
                )}
            </button>
        </div>
    );
}

export default LOADING_BUTTON;
