import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useAuth } from '../../context/AuthContext';
import { LanguageContext } from '../../context/LanguageContext';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import TOPSECTION from '../private/TopSection';
import USERPROFILE from '../../pages/public/UserProfile'
import '../../style/common.css';
import '../../style/private/commonPrivate.css';

const HOOKER_USERPROFILE = () => {
    const { isAuthenticated } = useAuth();
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;

    return (
        <>
            <section className='private-common-wrapper'>
                <Helmet>
                    <meta name="robots" content="noindex, nofollow" />
                    <script type="text/javascript">
                        {`
                    (function(c,l,a,r,i,t,y){
                        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                        })(window, document, "clarity", "script", "nm5kj82ams");
                    `}
                    </script> {/* -------- MICROSOFT CLARITY -------- */}
                    <script async src="https://www.googletagmanager.com/gtag/js?id=G-TZESFWRR8N"></script>
                    <script>
                        {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                    
                        gtag('config', 'G-TZESFWRR8N');
                    `}
                    </script> {/* -------- GOOGLE ANALYTICS -------- */}
                </Helmet>

                <div className='private-common-wrapper'>
                    <div className='private-common-top'>
                        {isAuthenticated ? (
                            <TOPSECTION />
                        ) : (
                            <div className='public-common-top'>
                                <div className='public-common-top-wrapper'>
                                    <div className='public-common-top-title'>
                                        <a href='/mural'><h1 translate='no'>Match the Look</h1></a>
                                    </div>
                                    <div className='public-common-top-login'>
                                        <button>{translations.topSection_nonAuth_login}</button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='private-common-content'>
                        <USERPROFILE />
                    </div>
                </div>
            </section>
        </>
    );
};

export default HOOKER_USERPROFILE;
