import { createContext, useContext, useState } from 'react';

const SharesContext = createContext();

export function useShares() {
  return useContext(SharesContext);
}

export function SharesProvider({ children }) {
  const [sharesData, setSharesData] = useState([]);
  const [page, setPage] = useState(1);
  const [index, setIndex] = useState(1);
  const [end, setEnd] = useState(true);
  const [loadedSharesCache, setLoadedSharesCache] = useState([]);

  return (
    <SharesContext.Provider value={{ sharesData, setSharesData, page, setPage, index, setIndex,loadedSharesCache, setLoadedSharesCache, end, setEnd }}>
      {children}
    </SharesContext.Provider>
  );
}