import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { LanguageContext } from '../context/LanguageContext';
import enTranslations from '../translations/en.json'
import ptTranslations from '../translations/pt.json'
import LoadingButton from './loader';
import './newShareOptions.css';

function NEWSHAREOPTIONS({ isScrollLocked, actionOnClick, otherOptions, openOrCloseOptions }) {
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;

    const [audienceSelected, setAudienceSelect] = useState(otherOptions.audience);
    const [budgetSelected, setBudgetSelect] = useState(otherOptions.budget);
    const [seasonSelected, setSeasonSelected] = useState(otherOptions.season);
    const [sustentabilitySelected, setSustentabilitySelected] = useState(otherOptions.sustentability);
    const [bodyTypeSelected, setBodyTypeSelected] = useState(otherOptions.bodyType);

    const saveOptions= () => {
        const data = {
            audience: audienceSelected,
            budget: budgetSelected,
            season: seasonSelected,
            sustentability: sustentabilitySelected,
            bodyType: bodyTypeSelected
        }

        actionOnClick(data);
    }


    useEffect(() => {
        if (isScrollLocked) {
            // Lock the scroll
            document.body.style.overflow = 'hidden';
            document.body.style.touchAction = 'none';

            // Smoothly scroll to the top when the scroll is locked
            window.scrollTo({
                top: 0,
                behavior: 'smooth',  // Smooth scrolling
            });
        } else {
            // Unlock the scroll
            document.body.style.overflow = '';
            document.body.style.touchAction = '';
        }

        return () => {
            document.body.style.overflow = '';
            document.body.style.touchAction = '';
        };
    }, [isScrollLocked]);

    return (
        <div className='newShare-options-background'>
            <div className='newShare-options-background-container'>

                <div className='newShare-options-title'>
                    <img src='/private/otherOptions_icon.png' alt='' />
                    <h2>{translations.components_newShareOptions_mainTitle}</h2>
                </div>

                <div className='newShare-options-close'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" onClick={openOrCloseOptions}>
                        <path d="M19.0005 4.99988L5.00049 18.9999M5.00049 4.99988L19.0005 18.9999" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>

                <div className='mural-filter-options'>
                <h3>{translations.components_filters_audience}</h3>
                    <div className='mural-filter-options-buttons'>
                        <button
                            className={audienceSelected === 'oversized' ? 'mural-filter-options-buttons-active' : 'mural-filter-options-buttons-disabled'}
                            onClick={() => setAudienceSelect(audienceSelected === 'oversized' ? '' : 'oversized')}>
                            {translations.components_filters_audienceSizeXL}
                        </button>
                        <button
                            className={audienceSelected === 'children' ? 'mural-filter-options-buttons-active' : 'mural-filter-options-buttons-disabled'}
                            onClick={() => setAudienceSelect(audienceSelected === 'children' ? '' : 'children')}>
                            {translations.components_filters_audienceForChildren}
                        </button>
                        <button
                            className={audienceSelected === 'teenager' ? 'mural-filter-options-buttons-active' : 'mural-filter-options-buttons-disabled'}
                            onClick={() => setAudienceSelect(audienceSelected === 'teenager' ? '' : 'teenager')}>
                            {translations.components_filters_audienceForYouth}
                        </button>
                    </div>

                </div>

                <div className='mural-filter-options'>
                <h3>{translations.components_filters_budget}</h3>
                    <div className='mural-filter-options-buttons'>
                        <button
                            className={budgetSelected === 'cheap' ? 'mural-filter-options-buttons-active' : 'mural-filter-options-buttons-disabled'}
                            onClick={() => setBudgetSelect(budgetSelected === 'cheap' ? '' : 'cheap')}>
                            {translations.components_filters_budgetAffordable}
                        </button>
                        <button
                            className={budgetSelected === 'luxury' ? 'mural-filter-options-buttons-active' : 'mural-filter-options-buttons-disabled'}
                            onClick={() => setBudgetSelect(budgetSelected === 'luxury' ? '' : 'luxury')}>
                            {translations.components_filters_budgetLuxury}
                        </button>
                    </div>
                </div>

                <div className='mural-filter-options'>
                <h3>{translations.components_filters_season}</h3>
                    <div className='mural-filter-options-buttons'>
                        <button
                            className={seasonSelected === 'summer' ? 'mural-filter-options-buttons-active' : 'mural-filter-options-buttons-disabled'}
                            onClick={() => setSeasonSelected(seasonSelected === 'summer' ? '' : 'summer')}>
                            {translations.components_filters_seasonSummer}
                        </button>
                        <button
                            className={seasonSelected === 'winter' ? 'mural-filter-options-buttons-active' : 'mural-filter-options-buttons-disabled'}
                            onClick={() => setSeasonSelected(seasonSelected === 'winter' ? '' : 'winter')}>
                            {translations.components_filters_seasonWinter}
                        </button>
                    </div>
                </div>

                <div className='mural-filter-options'>
                <h3>{translations.components_filters_sustainability}</h3>
                    <div className='mural-filter-options-buttons'>
                        <button
                            className={sustentabilitySelected === 'sustainability' ? 'mural-filter-options-buttons-active' : 'mural-filter-options-buttons-disabled'}
                            onClick={() => setSustentabilitySelected(sustentabilitySelected === 'sustainability' ? '' : 'sustainability')}>
                            {translations.components_filters_sustainabilitySustainableFashion}
                        </button>
                        <button
                            className={sustentabilitySelected === 'secondLife' ? 'mural-filter-options-buttons-active' : 'mural-filter-options-buttons-disabled'}
                            onClick={() => setSustentabilitySelected(sustentabilitySelected === 'secondLife' ? '' : 'secondLife')}>
                            {translations.components_filters_sustainabilitySecondHandPieces}
                        </button>
                    </div>
                </div>

                <div className='mural-filter-options'>
                <h3>{translations.components_filters_bodyType}</h3>
                    <div className='mural-filter-options-buttons'>
                        <button
                            className={bodyTypeSelected === 'slimfit' ? 'mural-filter-options-buttons-active' : 'mural-filter-options-buttons-disabled'}
                            onClick={() => setBodyTypeSelected(bodyTypeSelected === 'slimfit' ? '' : 'slimfit')}>
                            {translations.components_filters_bodyTypeSlimFit}
                        </button>
                        <button
                            className={bodyTypeSelected === 'oversized' ? 'mural-filter-options-buttons-active' : 'mural-filter-options-buttons-disabled'}
                            onClick={() => setBodyTypeSelected(bodyTypeSelected === 'oversized' ? '' : 'oversized')}>
                            {translations.components_filters_bodyTypeOversizedLooks}
                        </button>
                        <button
                            className={bodyTypeSelected === 'adjusted' ? 'mural-filter-options-buttons-active' : 'mural-filter-options-buttons-disabled'}
                            onClick={() => setBodyTypeSelected(bodyTypeSelected === 'adjusted' ? '' : 'adjusted')}>
                            {translations.components_filters_bodyTypeFittedLooks}
                        </button>
                    </div>
                </div>


                <LoadingButton
                    buttonText={translations.components_filters_saveButton}
                    loading={false}
                    actionOnClick={saveOptions}
                />
            </div>
        </div>
    );
}

export default NEWSHAREOPTIONS;