import React, { useState } from 'react';
import NewReferenceHandler from '../../../components/newReference';
import LoadingButton from '../../../components/loader';
import '../../../style/private/newShare/propertiesReferences.css'

const referencesTranslations = {
    en: {
        // Clothing
        jacket: 'Jacket',
        blazer: 'Blazer',
        vest: 'Vest',
        sweater: 'Sweater',
        blouse: 'Blouse',
        dress: 'Dress',
        polo: 'Polo',
        shirt: 'Shirt',
        tshirt: 'T-shirt',
        pants: 'Pants',
        jeans: 'Jeans',
        shorts: 'Shorts',
        skirt: 'Skirt',
        pijama: 'Pajama',
        swimwear: 'Swimwear',
        boxers: 'Boxers',
        bra: 'Bra',
        panties: 'Panties',
        socks: 'Socks',
        other: 'Other',

        // Accessories
        cap: 'Cap',
        hat: 'Hat',
        gloves: 'Gloves',
        scarf: 'Scarf',
        watch: 'Watch',
        belt: 'Belt',
        handbag: 'Handbag',
        necklace: 'Necklace',
        ring: 'Ring',
        earrings: 'Earrings',
        bag: 'Bag',
        bagpack: 'Backpack',
        tie: 'Tie',
        bowtie: 'Bowtie',
        bracelet: 'Bracelet',
        wallet: 'Wallet',
        glasses: 'Glasses',

        // Footwear
        shoes: 'Shoes',
        sneakers: 'Sneakers',
        sandals: 'Sandals',
        mocassins: 'Mocassins',
        boots: 'Boots',
        ankleboots: 'Ankle Boots',
        highheels: 'High Heels',
        elegantshoes: 'Elegant Shoes',
        flipflops: 'Flip-flops',
        slippers: 'Slippers'
    },

    pt: {
        // Clothing
        jacket: 'Casaco',
        blazer: 'Blazer',
        vest: 'Colete',
        sweater: 'Camisola',
        blouse: 'Blusa',
        dress: 'Vestido',
        polo: 'Polo',
        shirt: 'Camisa',
        tshirt: 'T-shirt',
        pants: 'Calças',
        jeans: 'Calças de Ganga',
        shorts: 'Calções',
        skirt: 'Saia',
        pijama: 'Pijama',
        swimwear: 'Fato de banho',
        boxers: 'Boxers',
        bra: 'Sutiã',
        panties: 'Cuecas',
        socks: 'Meias',
        other: 'Outro',

        // Accessories
        cap: 'Boné',
        hat: 'Chapéu',
        gloves: 'Luvas',
        scarf: 'Cachecol',
        watch: 'Relógio',
        belt: 'Cinto',
        handbag: 'Mala',
        necklace: 'Colar',
        ring: 'Anel',
        earrings: 'Brincos',
        bag: 'Saco',
        bagpack: 'Mochila',
        tie: 'Gravata',
        bowtie: 'Laço',
        bracelet: 'Pulseira',
        wallet: 'Carteira',
        glasses: 'Óculos',

        // Footwear
        shoes: 'Sapatos',
        sneakers: 'Ténis',
        sandals: 'Sandálias',
        mocassins: 'Mocassins',
        boots: 'Botas',
        ankleboots: 'Botins',
        highheels: 'Saltos Altos',
        elegantshoes: 'Sapatos Elegantes',
        flipflops: 'Chinelos',
        slippers: 'Pantufas'
    }
};

function PropertiesNewShare({ clothing, setClothing, accessories, setAccessories, footwear, setFootwear, loading, setNewShareStep, submitShare, translations, language, allowToContinue2 }) {

    const [handlerReferenceOpen, setHandlerReferenceOpen] = useState(false);

    const [t_itemKety, t_setItemKey] = useState('');
    const [t_translatedName, t_setTranslatedName] = useState('');
    const [t_type, t_setType] = useState('');
    const [t_value, t_setValue] = useState('');
    const [isScrollLocked, setIsScrollLocked] = useState(false);


    const handleOpeningHandler = (itemKey, translatedName, type, value) => {
        t_setItemKey(itemKey);
        t_setTranslatedName(translatedName);
        t_setType(type);
        t_setValue(value);

        setIsScrollLocked(true);
        setHandlerReferenceOpen(true);
    }

    const closingHandlerWithoutChanges = () => {
        t_setItemKey('');
        t_setTranslatedName('');
        t_setType('');

        setIsScrollLocked(false);
        setHandlerReferenceOpen(false);
    }

    const handleUpdatingReferencences = (key, url) => {
        if (t_type === 'clothing') {
            setClothing(prevState => {
                const newItems = { ...prevState.items };
                if (url === '') {
                    newItems[key] = '';
                    return {
                        ...prevState,
                        items: newItems,
                        count: prevState.count - 1
                    };
                } else {
                    newItems[key] = url;
                    return {
                        ...prevState,
                        items: newItems,
                        count: prevState.count + 1
                    };
                }
            });
        } else if (t_type === 'accessories') {
            setAccessories(prevState => {
                const newItems = { ...prevState.items };
                if (url === '') {
                    newItems[key] = '';
                    return {
                        ...prevState,
                        items: newItems,
                        count: prevState.count - 1
                    };
                } else {
                    newItems[key] = url;
                    return {
                        ...prevState,
                        items: newItems,
                        count: prevState.count + 1
                    };
                }
            });
        } else if (t_type === 'footwear') {
            setFootwear(prevState => {
                const newItems = { ...prevState.items };
                if (url === '') {
                    newItems[key] = '';
                    return {
                        ...prevState,
                        items: newItems,
                        count: prevState.count - 1
                    };
                } else {
                    newItems[key] = url;
                    return {
                        ...prevState,
                        items: newItems,
                        count: prevState.count + 1
                    };
                }
            });
        }

        t_setItemKey('');
        t_setTranslatedName('');
        t_setType('');

        setIsScrollLocked(false);
        setHandlerReferenceOpen(false);
    };

    return (
        <section className='newshare-properties-references-wrapper'>
            <div className='newshare-properties-properties-list-title'>
                <h2>{translations.private_newShare_referencesForShare}</h2>
            </div>
            <div className="newshare-properties-properties-list-options-wrapper">

                {/* Clothing Section */}
                <div className="newshare-properties-properties-list-options">
                    <h3>{translations.private_newShare_clothes}</h3>
                    <div className="newshare-properties-properties-list-options-container">
                        {Object.keys(clothing.items).map((itemKey) => {
                            const item = clothing.items[itemKey];
                            const translatedName = referencesTranslations[language][itemKey] || itemKey;
                            const itemClass = item !== ''
                                ? 'newshare-properties-properties-references-options-active'
                                : 'newshare-properties-properties-references-options-disabled';

                            return (
                                <button key={itemKey} className={itemClass} onClick={() => handleOpeningHandler(itemKey, translatedName, 'clothing', item)}>
                                    <img src={`/references/icon-${itemKey}.png`} alt={translatedName} />
                                    <span>{translatedName}</span>
                                </button>
                            );
                        })}
                    </div>
                </div>

                {/* Accessories Section */}
                <div className="newshare-properties-properties-list-options">
                    <h3>{translations.private_newShare_accessories}</h3>
                    <div className="newshare-properties-properties-list-options-container">
                        {Object.keys(accessories.items).map((itemKey) => {
                            const item = accessories.items[itemKey];
                            const translatedName = referencesTranslations[language][itemKey] || itemKey;
                            const itemClass = item !== ''
                                ? 'newshare-properties-properties-references-options-active'
                                : 'newshare-properties-properties-references-options-disabled';

                            return (
                                <button key={itemKey} className={itemClass} onClick={() => handleOpeningHandler(itemKey, translatedName, 'accessories', item)}>
                                    <img src={`/references/icon-${itemKey}.png`} alt={translatedName} />
                                    <span>{translatedName}</span>
                                </button>
                            );
                        })}
                    </div>
                </div>

                {/* Footwear Section */}
                <div className="newshare-properties-properties-list-options">
                    <h3>{translations.private_newShare_footwear}</h3>
                    <div className="newshare-properties-properties-list-options-container">
                        {Object.keys(footwear.items).map((itemKey) => {
                            const item = footwear.items[itemKey];
                            const translatedName = referencesTranslations[language][itemKey] || itemKey;
                            const itemClass = item !== ''
                                ? 'newshare-properties-properties-references-options-active'
                                : 'newshare-properties-properties-references-options-disabled';

                            return (
                                <button key={itemKey} className={itemClass} onClick={() => handleOpeningHandler(itemKey, translatedName, 'footwear', item)}>
                                    <img src={`/references/icon-${itemKey}.png`} alt={translatedName} />
                                    <span>{translatedName}</span>
                                </button>
                            );
                        })}
                    </div>
                </div>
            </div>

            <div className='newshare-properties-properties-action-button'>
                <div className='newshare-properties-properties-action-button-return'>
                    <button onClick={() => setNewShareStep(1)}>
                        {translations.private_newShare_return}
                    </button>
                </div>

                {allowToContinue2 ? (
                    <LoadingButton
                        buttonText={translations.private_newShare_publish}
                        loading={loading}
                        actionOnClick={submitShare}
                    />
                ) : (
                    <button disabled>{translations.private_newShare_publish}</button>
                )}
            </div>

            {handlerReferenceOpen && (
                <NewReferenceHandler
                    isScrollLocked={isScrollLocked}
                    actionOnClick={handleUpdatingReferencences}
                    closing={closingHandlerWithoutChanges}
                    image={t_itemKety}
                    title={t_translatedName}
                    value={t_value}
                />
            )}

        </section >
    );
}

export default PropertiesNewShare;