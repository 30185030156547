import { useState, useEffect, useContext } from 'react';
import { useShares } from '../../context/SharesContext';
import { useAuth } from '../../context/AuthContext';
import { LanguageContext } from '../../context/LanguageContext';
import MainContext from '../../context/MainContext';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import LoadingButton from '../../components/loader';
import Grid from '../../components/grid';
import MuralFilters from '../../components/mural_filters';
import '../../style/private/Mural.css';

function PRIVATE_MURAL() {
  const { getSessionInfo } = useAuth();
  const { localStoredEmail, localStoredUsername, localStoredSession } = getSessionInfo();
  const { language } = useContext(LanguageContext);
  const translations = language === 'en' ? enTranslations : ptTranslations;

  const [filtersOpen, setFiltersOpen] = useState('');
  const [isScrollLocked, setIsScrollLocked] = useState(false);

  const [imageLoadingFromStorage, setImageLoadingFromStorage] = useState(true);

  const gender = (localStorage.getItem('private-mural-shares-filter-gender') || localStorage.getItem('gender'));
  const style = (localStorage.getItem('private-mural-shares-filter-style') || 'all');
  const audience = (localStorage.getItem('private-mural-shares-filter-audience') || '');
  const budget = (localStorage.getItem('private-mural-shares-filter-budget') || '');
  const season = (localStorage.getItem('private-mural-shares-filter-season') || '');
  const sustentability = (localStorage.getItem('private-mural-shares-filter-sustentability') || '');
  const bodyType = (localStorage.getItem('private-mural-shares-filter-bodyType') || '');


  const [loadMoreShares, setLoadMoreShares] = useState(false);
  const [noShares, setNoShares] = useState(false);
  const [isInitialDataBeingLoaded, setIsInitialDataBeingLoaded] = useState(true);

  const handleOpenFilters = () => {
    setFiltersOpen(!filtersOpen);
    setIsScrollLocked(!isScrollLocked);
  };

  const closeFilters = (newFilters) => {

    if (newFilters.gender !== '') {
      localStorage.setItem('private-mural-shares-filter-gender', newFilters.gender);
    } else {
      localStorage.removeItem('private-mural-shares-filter-gender');
    }

    if (newFilters.style !== '') {
      localStorage.setItem('private-mural-shares-filter-style', newFilters.style);
    } else {
      localStorage.removeItem('private-mural-shares-filter-style');
    }

    if (newFilters.audience !== '') {
      localStorage.setItem('private-mural-shares-filter-audience', newFilters.audience);
    } else {
      localStorage.removeItem('private-mural-shares-filter-audience');
    }

    if (newFilters.budget !== '') {
      localStorage.setItem('private-mural-shares-filter-budget', newFilters.budget);
    } else {
      localStorage.removeItem('private-mural-shares-filter-budget');
    }

    if (newFilters.season !== '') {
      localStorage.setItem('private-mural-shares-filter-season', newFilters.season);
    } else {
      localStorage.removeItem('private-mural-shares-filter-season');
    }

    if (newFilters.sustentability !== '') {
      localStorage.setItem('private-mural-shares-filter-sustentability', newFilters.sustentability);
    } else {
      localStorage.removeItem('private-mural-shares-filter-sustentability');
    }

    if (newFilters.bodyType !== '') {
      localStorage.setItem('private-mural-shares-filter-bodyType', newFilters.bodyType);
    } else {
      localStorage.removeItem('private-mural-shares-filter-bodyType');
    }

    localStorage.removeItem('private-mural-shares');
    localStorage.removeItem('private-mural-shares-end');

    window.location.href = '';
  }

  const { sharesData, setSharesData, setLoadedSharesCache, end, setEnd } = useShares();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setIsInitialDataBeingLoaded(true);

    localStorage.setItem('private-mural-shares-filter-gender', gender);
    localStorage.setItem('private-mural-shares-filter-style', style);

    const cachedShares = localStorage.getItem('private-mural-shares');
    const cachedEnd = localStorage.getItem('private-mural-shares-end');

    setLoadMoreShares(!cachedEnd);

    const isFirstTime = !cachedShares || !cachedEnd;

    if (!isFirstTime) {
      const parsedShares = JSON.parse(cachedShares);

      if (parsedShares.length > 0) {
        setSharesData(parsedShares);
        setLoadedSharesCache(parsedShares);
        setEnd(JSON.parse(cachedEnd));

        const cachedSharesString = JSON.parse(cachedShares);
        const firstShareValue = cachedSharesString[0].share;

        setIsInitialDataBeingLoaded(false);

        checkForUpdateShares(firstShareValue);
      } else {
        setIsInitialDataBeingLoaded(true);
        loadFirstShares();
      }
    } else {
      setIsInitialDataBeingLoaded(true);
      loadFirstShares();
    }
  }, []);
  /* eslint-disable react-hooks/exhaustive-deps */

  async function loadFirstShares() {
    const baseURL = `${MainContext.apiURL}/api/v1/private/shares/fetch/shares`;
    const queryParams = new URLSearchParams({
      gender: gender,
      style: style,
    });

    try {
      const response = await fetch(`${baseURL}?${queryParams}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'email': localStoredEmail,
          'username': localStoredUsername,
          'session': localStoredSession,
        },
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      if (data.end && !data.sharesAvailable) {
        setNoShares(true);
        setIsInitialDataBeingLoaded(false);
        localStorage.setItem('private-mural-shares', JSON.stringify([]));
      } else {
        const newShares = [...sharesData, ...data.shares];
        setSharesData(newShares);
        setLoadedSharesCache(prev => [...prev, ...data.shares]);
        setEnd(data.end);
        setIsInitialDataBeingLoaded(false);

        localStorage.setItem('private-mural-shares', JSON.stringify(newShares));
        localStorage.setItem('private-mural-shares-end', JSON.stringify(data.end));
      }
    } catch (error) {
      console.error('Failed to load shares:', error);
    } finally {
      setLoadMoreShares(false);
      setIsInitialDataBeingLoaded(false);
    }
  }

  async function checkForUpdateShares(firstShare) {
    const baseURL = `${MainContext.apiURL}/api/v1/private/shares/update/shares`;

    let queryParams = new URLSearchParams({
      gender: gender,
      style: style,
      lastShare: firstShare
    });

    try {
      const response = await fetch(`${baseURL}?${queryParams}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'email': localStoredEmail,
          'username': localStoredUsername,
          'session': localStoredSession,
        },
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      if (data.sharesAvailable) {
        const cachedShares = localStorage.getItem('private-mural-shares');
        const parsedCachedShares = cachedShares ? JSON.parse(cachedShares) : [];

        const updatedShares = [...data.shares, ...parsedCachedShares];

        setSharesData(updatedShares);
        setLoadedSharesCache(updatedShares);

        setEnd(data.end);

        localStorage.setItem('private-mural-shares', JSON.stringify(updatedShares));
        localStorage.setItem('private-mural-shares-end', JSON.stringify(data.end));
      }
    } catch (error) {
      console.error('Failed to load shares:', error);
    } finally {
      setLoadMoreShares(false);
    }
  }

  async function loadOlderShares() {
    setLoadMoreShares(true);

    const cachedShares = localStorage.getItem('private-mural-shares');
    let lastShare;

    if (cachedShares) {
      const sharesArray = JSON.parse(cachedShares);

      if (Array.isArray(sharesArray) && sharesArray.length > 0) {
        lastShare = sharesArray[sharesArray.length - 1].share;
      } else {
        lastShare = '';
      }
    } else {
      lastShare = '';
    }

    if (lastShare === '') {
      return;
    }

    const baseURL = `${MainContext.apiURL}/api/v1/private/shares/fetch/older-shares`;

    const queryParams = new URLSearchParams({
      gender: gender,
      style: style,
      lastShare: lastShare
    });

    try {
      const response = await fetch(`${baseURL}?${queryParams}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'email': localStoredEmail,
          'username': localStoredUsername,
          'session': localStoredSession,
        },
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      if (data.sharesAvailable) {
        const cachedShares = localStorage.getItem('private-mural-shares');
        const parsedCachedShares = cachedShares ? JSON.parse(cachedShares) : [];

        const updatedShares = [...parsedCachedShares, ...data.shares];

        setSharesData(updatedShares);
        setLoadedSharesCache(updatedShares);

        setEnd(data.end);

        localStorage.setItem('private-mural-shares', JSON.stringify(updatedShares));
        localStorage.setItem('private-mural-shares-end', JSON.stringify(data.end));
      } else {
        setEnd(true);
        localStorage.setItem('private-mural-shares-end', JSON.stringify(true));
      }

    } catch (error) {
      console.error('Failed to load shares:', error);
    } finally {
      setLoadMoreShares(false);
    }
  }

  return (
    <section className='private-mural-wrapper'>
      <div className='private-mural-container'>

        {isInitialDataBeingLoaded ? (
          <div className='private-mural-initial-loading'>
            <svg xmlns="http://www.w3.org/2000/svg" height="128px" width="128px" viewBox="0 0 128 128" class="pl">
              <defs>
                <linearGradient y2="1" x2="0" y1="0" x1="0" id="pl-grad">
                  <stop stop-color="hsl(193,90%,55%)" offset="0%"></stop>
                  <stop stop-color="hsl(223,90%,55%)" offset="100%"></stop>
                </linearGradient>
              </defs>
              <circle stroke-linecap="round" stroke-width="16" stroke="hsla(0,10%,10%,0.1)" fill="none" cy="64" cx="64" r="56" class="pl__ring"></circle>
              <path stroke-dashoffset="10" stroke-dasharray="44 1111" stroke-linejoin="round" stroke-linecap="round" stroke-width="16" stroke="url(#pl-grad)" fill="none" d="M92,15.492S78.194,4.967,66.743,16.887c-17.231,17.938-28.26,96.974-28.26,96.974L119.85,59.892l-99-31.588,57.528,89.832L97.8,19.349,13.636,88.51l89.012,16.015S81.908,38.332,66.1,22.337C50.114,6.156,36,15.492,36,15.492a56,56,0,1,0,56,0Z" class="pl__worm"></path>
            </svg>

            <div class="private-mural-initial-loading-loadingtext">
              <p>{translations.private_mural_loading}</p>
            </div>

          </div>
        ) : (
          <>
            <div className='private-mural-filter'>
              <button onClick={handleOpenFilters}>
                <img src='/private/filter_icon.png' alt='' />
                {translations.private_mural_filters}
              </button>
            </div>

            {noShares ? (
              <div className='private-mural-shares-noshares'>
                <h3>{translations.private_mural_noShares_message01}</h3>
                <span>{translations.private_mural_noShares_message02}</span>
              </div>
            ) : (
              <>
                <div className='private-mural-shares-grid'>
                  <Grid
                    shares={sharesData}
                    imageLoadingFromStorage={imageLoadingFromStorage}
                    setImageLoadingFromStorage={setImageLoadingFromStorage}
                    MainContext={MainContext}
                  />
                </div>
                {!end && (
                  <div className='private-mural-button-load-more'>
                    <LoadingButton
                      buttonText={translations.private_mural_loadMore}
                      loading={loadMoreShares}
                      actionOnClick={loadOlderShares}
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}

        {filtersOpen && (
          <MuralFilters
            isScrollLocked={true}
            actionOnClick={closeFilters}
            closeWithoutChanges={handleOpenFilters}
            gender={gender}
            style={style}
            audience={audience}
            budget={budget}
            season={season}
            sustentability={sustentability}
            bodyType={bodyType}
          />
        )}

      </div>
    </section>
  );
}

export default PRIVATE_MURAL;