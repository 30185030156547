import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { LanguageContext } from '../../context/LanguageContext';
import MainContext from '../../context/MainContext';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import TOPSECTION from './TopSection';
import BOTTOMSECTION from './BottomSection';
import LoadingWithText from '../../components/loadingWithText';
import REGISTER_STEP1 from '../../pages/auth/Register_Step1'
import REGISTER_STEP2 from '../../pages/auth/Register_Step2'
import REGISTER_STEP3 from '../../pages/auth/Register_Step3'
import REGISTER_STEP4 from '../../pages/auth/Register_Step4'
import REGISTER_STEP5 from '../../pages/auth/Register_Step5'
import REGISTER_STEP6 from '../../pages/auth/Register_Step6'
import REGISTER_STEP7 from '../../pages/auth/Register_Step7'
import '../../style/common.css';
import '../../style/auth/commonAuth.css';

const HOOKER_LOGIN = () => {
    const location = useLocation();
    const { username, referralCode, userPlan } = useParams();
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;

    const [loading, setLoading] = useState(false);

    const [registerStep, setRegisterStep] = useState(1);
    const [referreUserDisplayName, setReferreUserDisplayName] = useState('');
    const [referreUserProfilePicture, setReferreUserProfilePicture] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [usernameChoosen, setUsernameChoosen] = useState('');
    const [securityToken, setSecurityToken] = useState('');

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (location.pathname === `/${language}/register/payment/success`) {
            setRegisterStep(7);
        }
    }, [location.pathname]);
    /* eslint-disable react-hooks/exhaustive-deps */

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (username && referralCode) {
            getReferralUserInfo();
        }
    }, [location.pathname]);
    /* eslint-disable react-hooks/exhaustive-deps */

    const generateSecureLinkForPayment = (billingPlan) => {
        const baseURL = `${MainContext.apiURL}/api/v1/public/users/pay/subscription`;

        const formData = new URLSearchParams({
            email: email,
            language: language,
            plan: billingPlan
        });

        fetch(baseURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formData.toString(),
        })
            .then(response => {
                if (!response.ok) {
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                const currentTimestampUTC = new Date().toISOString();

                const paymentIntentObject = {
                    timestamp: currentTimestampUTC
                };

                localStorage.setItem('subscription-payment-intent', JSON.stringify(paymentIntentObject));
                localStorage.setItem('subscription-payment-intent-plan', userPlan);

                window.location.href = data.session;
            })
            .catch(error => {
                console.error('Error activating account:', error);
            });
    }

    const getReferralUserInfo = () => {
        setLoading(true);
        const data = new URLSearchParams({
            username: username.toLowerCase(),
            referralCode: referralCode,
        });

        fetch(`${MainContext.apiURL}/api/v1/public/users/profile/referral?${data}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (response.status === 201) {
                    setLoading(false);
                    return window.location.href = `/${language}/register`
                } else {
                    return response.json();
                }
            })
            .then(data => {
                setLoading(false);
                setReferreUserDisplayName(data.result[0].displayname);
                setReferreUserProfilePicture(data.result[0].profilepicture);
            })
            .catch(error => {
                console.log(error);
                return window.location.href = `/${language}/register`
            });
    }


    return (
        <section className='common-public-wrapper'>
            <Helmet>
                <title>{translations.seo_landingPage_title}</title>
                <script type="text/javascript">
                    {`
                    (function(c,l,a,r,i,t,y){
                        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                        })(window, document, "clarity", "script", "nm5kj82ams");
                    `}
                </script> {/* -------- MICROSOFT CLARITY -------- */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-TZESFWRR8N"></script>
                <script>
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                    
                        gtag('config', 'G-TZESFWRR8N');
                    `}
                </script> {/* -------- GOOGLE ANALYTICS -------- */}
            </Helmet>

            <div className='auth-common-wrapper'>
                {loading && (
                    <LoadingWithText />
                )}
                <div className='auth-common-top'>
                    <TOPSECTION />
                </div>
                <div className='auth-common-content'>
                    {registerStep === 1 && (
                        <REGISTER_STEP1
                            setRegisterStep={setRegisterStep}
                            username={username}
                            referralCode={referralCode}
                            displayName={referreUserDisplayName}
                            profilePicture={referreUserProfilePicture}
                            email={email}
                            setEmail={setEmail}
                            userPlan={userPlan}
                        />
                    )}
                    {registerStep === 2 && (
                        <REGISTER_STEP2
                            setRegisterStep={setRegisterStep}
                            email={email}
                            setSecurityToken={setSecurityToken}
                        />
                    )}
                    {registerStep === 3 && (
                        <REGISTER_STEP3
                            setRegisterStep={setRegisterStep}
                            email={email}
                            setPassword={setPassword}
                        />
                    )}
                    {registerStep === 4 && (
                        <REGISTER_STEP4
                            setRegisterStep={setRegisterStep}
                            setDisplayName={setDisplayName}
                            setUsername={setUsernameChoosen}
                        />
                    )}
                    {registerStep === 5 && (
                        <REGISTER_STEP5
                            setRegisterStep={setRegisterStep}
                            userPlan={userPlan}
                            email={email}
                            password={password}
                            displayName={displayName}
                            usernameChoosen={usernameChoosen}
                            referralCode={referralCode}
                            securityToken={securityToken}
                            generateSecureLinkForPayment={generateSecureLinkForPayment}
                        />
                    )}
                    {registerStep === 6 && (
                        <REGISTER_STEP6
                            setRegisterStep={setRegisterStep}
                            generateSecureLinkForPayment={generateSecureLinkForPayment}
                        />
                    )}
                    {registerStep === 7 && (
                        <REGISTER_STEP7
                            setRegisterStep={setRegisterStep}
                        />
                    )}
                </div>
                <div className='auth-common-footer'>
                    <BOTTOMSECTION />
                </div>
            </div>
        </section>
    );
};

export default HOOKER_LOGIN;
