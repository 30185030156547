import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from './context/AuthContext';
import { LanguageProvider } from './context/LanguageContext';
import { SharesProvider } from './context/SharesContext';

import PUBLIC_LANDING_PAGE from './hookers/public/Hooker_LandingPage'
import PUBLIC_USER_PROFILE from './hookers/public/Hooker_PublicProfile'
import PUBLIC_SHARE from './hookers/public/Hooker_Share'

import AUTH_LOGIN from './hookers/auth/Hooker_Login'
import AUTH_REGISTER from './hookers/auth/Hooker_Register'
import AUTH_RECOVERY from './hookers/auth/Hooker_RecoverPassword'

import PRIVATE_MURAL from './hookers/private/Hooker_Mural'
import PRIVATE_AI from './hookers/private/Hooker_AI'
import PRIVATE_NEWSHARE from './hookers/private/Hooker_NewShare'
import PRIVATE_CHALLENGES from './hookers/private/Hooker_Challenges'
import PRIVATE_MY_SETTINGS from './hookers/private/Hooker_MySettings'
import PRIVATE_MY_PROFILE from './hookers/private/Hooker_MyProfile'
import PRIVATE_MY_PUBLIC_INFORMATION from './hookers/private/Hooker_PublicInformation'
import PRIVATE_MY_SUBSCRIPTION from './hookers/private/Hooker_MySubscription'
import PRIVATE_LINK_SOCIAL_MEDIA from './hookers/private/Hooker_LinkSocialMedia'

import PAYMENT_CONFIRMATION from './payments/PaymentConfirmation';

function App() {
  const { isAuthenticated } = useAuth();

  return (
    <Router>
      <LanguageProvider>
        <SharesProvider>
          <Routes>
            {/* -------------- PUBLIC ROUTES -------------- */}
            <Route path="/" element={<PUBLIC_LANDING_PAGE />} />
            <Route path="/:language/" element={<PUBLIC_LANDING_PAGE />} />
            <Route path="/:language/:username/" element={<PUBLIC_USER_PROFILE />} />
            <Route path="/:language/:username/share/:share" element={<PUBLIC_SHARE />} />

            {/* -------------- AUTH ROUTES -------------- */}
            <Route path="/:language/login" element={<AUTH_LOGIN />} />
            <Route path="/:language/register" element={<AUTH_REGISTER />} />
            <Route path="/:language/register/:userPlan" element={<AUTH_REGISTER />} />
            <Route path="/:language/:username/referral/:referralCode" element={<AUTH_REGISTER />} />
            <Route path="/:language/register/payment/success" element={<AUTH_REGISTER />} />
            <Route path="/:language/recover-password" element={<AUTH_RECOVERY />} />

            {/* -------------- PRIVATE ROUTES -------------- */}
            <Route path="/:language/account/:author/mural" element={isAuthenticated ? (<PRIVATE_MURAL />) : (<Navigate to="/" replace />)} />
            <Route path="/:language/account/:author/ai/chat" element={isAuthenticated ? (<PRIVATE_AI />) : (<Navigate to="/" replace />)} />
            <Route path="/:language/account/:author/new" element={isAuthenticated ? (<PRIVATE_NEWSHARE />) : (<Navigate to="/" replace />)} />
            <Route path="/:language/account/:author/edit/:share" element={isAuthenticated ? (<PRIVATE_NEWSHARE />) : (<Navigate to="/" replace />)} />
            <Route path="/:language/account/:author/challenges" element={isAuthenticated ? (<PRIVATE_CHALLENGES />) : (<Navigate to="/" replace />)} />
            <Route path="/:language/account/:author/my-settings" element={isAuthenticated ? (<PRIVATE_MY_SETTINGS />) : (<Navigate to="/" replace />)} />
            <Route path="/:language/account/:author/about-me" element={isAuthenticated ? (<PRIVATE_MY_PROFILE />) : (<Navigate to="/" replace />)} />
            <Route path="/:language/account/:author/my-info" element={isAuthenticated ? (<PRIVATE_MY_PUBLIC_INFORMATION />) : (<Navigate to="/" replace />)} />
            <Route path="/:language/account/:author/subscription" element={isAuthenticated ? (<PRIVATE_MY_SUBSCRIPTION />) : (<Navigate to="/" replace />)} />
            <Route path="/:language/account/:author/social-media" element={isAuthenticated ? (<PRIVATE_LINK_SOCIAL_MEDIA />) : (<Navigate to="/" replace />)} />

            {/* -------------- PAYMENT ROUTES -------------- */}
            <Route path="/:language/payment/confirmation" element={<PAYMENT_CONFIRMATION />} />

            {/* -------------- DEFAULT ROUTES -------------- */}
            <Route path="*" element={(<Navigate to="/" replace />)} />

          </Routes>
        </SharesProvider>
      </LanguageProvider>
    </Router >
  );
}

export default App;