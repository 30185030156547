import React, { useContext, useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { LanguageContext } from '../../context/LanguageContext';
import MainContext from '../../context/MainContext';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import '../../style/common.css';
import '../../style/private/commonPrivate.css';

const HOOKER_AUTH_TOP = () => {
    const location = useLocation();
    const { getSessionInfo, logout } = useAuth();
    const { language } = useContext(LanguageContext);
    const { localStoredUsername, localStoredProfilePicture, localStoredDisplayName } = getSessionInfo();
    const translations = language === 'en' ? enTranslations : ptTranslations;

    const [menuActive, setMenuActive] = useState('');
    const [profileMenuOpen, setProfileMenuOpen] = useState(false);
    const menuRef = useRef(null);

    useEffect(() => {
        const pathSegment = location.pathname.split('/')[4];
        setMenuActive(pathSegment);
    }, [location.pathname]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            // Check if the menu is open and if the click happened outside of it
            if (profileMenuOpen && menuRef.current && !menuRef.current.contains(event.target)) {
                setProfileMenuOpen(false); // Close the menu
            }
        };

        // Add event listener when the menu is open
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup event listener on component unmount or when the menu closes
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [profileMenuOpen]);


    return (
        <section className='private-top-wrapper'>
            <div className='private-top-title'>
                <a href={`/${language}/account/${localStoredUsername}/mural`}><h1 translate='no'>Match the Look</h1></a>
            </div>
            <div className='private-top-title-mobile'>
                <a href={`/${language}/account/${localStoredUsername}/mural`}><h1 translate='no'>MTL</h1></a>
            </div>
            <div className='private-top-menu'>
                <a href={`/${language}/account/${localStoredUsername}/mural`}>
                    <div className={menuActive === 'mural' ? 'private-top-menu-option-active' : 'private-top-menu-option'}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M7.08848 4.76364L6.08847 5.54453C4.57182 6.72887 3.81348 7.32105 3.40674 8.15601C3 8.99097 3 9.95552 3 11.8846V13.9767C3 17.763 3 19.6562 4.17157 20.8325C5.11466 21.7793 6.52043 21.964 9 22V18.0057C9 17.0738 9 16.6078 9.15224 16.2403C9.35523 15.7502 9.74458 15.3609 10.2346 15.1579C10.6022 15.0057 11.0681 15.0057 12 15.0057C12.9319 15.0057 13.3978 15.0057 13.7654 15.1579C14.2554 15.3609 14.6448 15.7502 14.8478 16.2403C15 16.6078 15 17.0738 15 18.0057V22C17.4796 21.964 18.8853 21.7793 19.8284 20.8325C21 19.6562 21 17.763 21 13.9767V11.8846C21 9.95552 21 8.99097 20.5933 8.15601C20.1865 7.32105 19.4282 6.72887 17.9115 5.54453L16.9115 4.76364C14.5521 2.92121 13.3724 2 12 2C10.6276 2 9.44787 2.92121 7.08848 4.76364Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                        </svg>
                        <span>{translations.topSection_home}</span>
                    </div>
                </a>

                <a href={`/${language}/account/${localStoredUsername}/ai/chat`}>
                    <div className={menuActive === 'ai' ? 'private-top-menu-option-active' : 'private-top-menu-option'}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M4 15.5C2.89543 15.5 2 14.6046 2 13.5C2 12.3954 2.89543 11.5 4 11.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M20 15.5C21.1046 15.5 22 14.6046 22 13.5C22 12.3954 21.1046 11.5 20 11.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M7 7L7 4" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                            <path d="M17 7L17 4" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                            <circle cx="7" cy="3" r="1" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                            <circle cx="17" cy="3" r="1" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                            <path d="M13.5 7H10.5C7.67157 7 6.25736 7 5.37868 7.90898C4.5 8.81796 4.5 10.2809 4.5 13.2069C4.5 16.1329 4.5 17.5958 5.37868 18.5048C6.25736 19.4138 7.67157 19.4138 10.5 19.4138H11.5253C12.3169 19.4138 12.5962 19.5773 13.1417 20.1713C13.745 20.8283 14.6791 21.705 15.5242 21.9091C16.7254 22.1994 16.8599 21.7979 16.5919 20.6531C16.5156 20.327 16.3252 19.8056 16.526 19.5018C16.6385 19.3316 16.8259 19.2898 17.2008 19.2061C17.7922 19.074 18.2798 18.8581 18.6213 18.5048C19.5 17.5958 19.5 16.1329 19.5 13.2069C19.5 10.2809 19.5 8.81796 18.6213 7.90898C17.7426 7 16.3284 7 13.5 7Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                            <path d="M9.5 15C10.0701 15.6072 10.9777 16 12 16C13.0223 16 13.9299 15.6072 14.5 15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M9.00896 11H9" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M15.009 11H15" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <span>{translations.topSection_AI}</span>
                    </div>
                </a>

                <a href={`/${language}/account/${localStoredUsername}/new`}>
                    <div className={menuActive === 'new' ? 'private-top-menu-option-active' : 'private-top-menu-option'}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M12 8V16M16 12L8 12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z" stroke="currentColor" stroke-width="1.5" />
                        </svg>
                        <span>{translations.topSection_new}</span>
                    </div>
                </a>

                <a href={`/${language}/account/${localStoredUsername}/challenges`}>
                    <div className={menuActive === 'challenges' ? 'private-top-menu-option-active' : 'private-top-menu-option'}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M4 11V15C4 18.2998 4 19.9497 5.02513 20.9749C6.05025 22 7.70017 22 11 22H13C16.2998 22 17.9497 22 18.9749 20.9749C20 19.9497 20 18.2998 20 15V11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M3 9C3 8.25231 3 7.87846 3.20096 7.6C3.33261 7.41758 3.52197 7.26609 3.75 7.16077C4.09808 7 4.56538 7 5.5 7H18.5C19.4346 7 19.9019 7 20.25 7.16077C20.478 7.26609 20.6674 7.41758 20.799 7.6C21 7.87846 21 8.25231 21 9C21 9.74769 21 10.1215 20.799 10.4C20.6674 10.5824 20.478 10.7339 20.25 10.8392C19.9019 11 19.4346 11 18.5 11H5.5C4.56538 11 4.09808 11 3.75 10.8392C3.52197 10.7339 3.33261 10.5824 3.20096 10.4C3 10.1215 3 9.74769 3 9Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                            <path d="M6 3.78571C6 2.79949 6.79949 2 7.78571 2H8.14286C10.2731 2 12 3.7269 12 5.85714V7H9.21429C7.43908 7 6 5.56091 6 3.78571Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                            <path d="M18 3.78571C18 2.79949 17.2005 2 16.2143 2H15.8571C13.7269 2 12 3.7269 12 5.85714V7H14.7857C16.5609 7 18 5.56091 18 3.78571Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                            <path d="M12 11L12 22" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <span>{translations.topSection_challenges}</span>
                    </div>
                </a>

            </div>
            <div className='private-top-profile' onClick={() => setProfileMenuOpen(!profileMenuOpen)}>
                {localStoredProfilePicture ? (
                    <img src={`${MainContext.profilePictureURL}${localStoredProfilePicture}`} alt='User Profile' />
                ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <circle cx="12" cy="12" r="10" stroke="currentColor" stroke-width="1.5" />
                        <path d="M7.5 17C9.8317 14.5578 14.1432 14.4428 16.5 17M14.4951 9.5C14.4951 10.8807 13.3742 12 11.9915 12C10.6089 12 9.48797 10.8807 9.48797 9.5C9.48797 8.11929 10.6089 7 11.9915 7C13.3742 7 14.4951 8.11929 14.4951 9.5Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                    </svg>
                )}
                <span>
                    {translations.topSection_profile}
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M18 9.00005C18 9.00005 13.5811 15 12 15C10.4188 15 6 9 6 9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </span>

                {profileMenuOpen && (
                    <div className='private-topMenu-profile-menu-open-wrapper' ref={menuRef}>
                        <div className='private-topMenu-profile-menu-open-background'>

                            <div className='private-topMenu-profile-menu-open-profile'>
                                {localStoredProfilePicture ? (
                                    <img src={`${MainContext.profilePictureURL}${localStoredProfilePicture}`} alt='User Profile' />
                                ) : (
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                        <circle cx="12" cy="12" r="10" stroke="currentColor" stroke-width="1.5" />
                                        <path d="M7.5 17C9.8317 14.5578 14.1432 14.4428 16.5 17M14.4951 9.5C14.4951 10.8807 13.3742 12 11.9915 12C10.6089 12 9.48797 10.8807 9.48797 9.5C9.48797 8.11929 10.6089 7 11.9915 7C13.3742 7 14.4951 8.11929 14.4951 9.5Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                                    </svg>
                                )}
                                <div className='private-topMenu-profile-menu-open-profile-userInfo'>
                                    <p>{localStoredDisplayName}</p>
                                    <p>@{localStoredUsername}</p>
                                </div>
                            </div>
                            <div className='private-topMenu-profile-menu-open-profile-button'>
                                <a href={`/${language}/${localStoredUsername}`}><button>{translations.topSection_viewProfile}</button></a>
                            </div>
                        </div>

                        <div className='private-topMenu-profile-menu-open-options'>

                            <div className='private-topMenu-profile-menu-open-options-wrapper'>
                                <h2>{translations.topSection_myAccount}</h2>
                                <a href={`/${language}/account/${localStoredUsername}/my-settings`}>{translations.topSection_settings}</a>
                            </div>

                            <div className='private-topMenu-profile-menu-open-options-wrapper'>
                                <a className='private-topMenu-profile-menu-open-options-wrapper-logout' onClick={() => logout(language)}>{translations.topSection_logout}</a>
                            </div>

                        </div>
                    </div>
                )}
            </div>
        </section>
    );
};

export default HOOKER_AUTH_TOP;
