import { useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { LanguageContext } from '../../context/LanguageContext';
import MainContext from '../../context/MainContext';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import LoadingButton from '../../components/loader';
import '../../style/auth/Login.css';

function AUTH_LOGIN() {
  const { username, share } = useParams();
  const { login } = useAuth();
  const navigate = useNavigate();
  const { language } = useContext(LanguageContext);
  const translations = language === 'en' ? enTranslations : ptTranslations;

  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const validateEmail = (inputEmail) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(inputEmail);
  };

  const loginUsers = (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setErrorMessage(translations.auth_login_emailError);
      setEmailError(true);
      return;
    } else if (password === '') {
      setErrorMessage(translations.auth_login_passwordError);
      setPasswordError(true);
      return;
    }
    
    setLoading(true);
    setErrorMessage('');
    setEmailError(false);
    setPasswordError(false);

    const baseURL = `${MainContext.apiURL}/api/v1/auth/users/login`;

    const formData = new URLSearchParams({
      email: email.toLowerCase(),
      password: password,
    });

    fetch(baseURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formData.toString(),
      credentials: 'include',
    })
      .then(response => {
        if (!response.ok) {
          setLoading(false);
          setErrorMessage(translations.auth_login_errorFailedLogin);
          return Promise.reject({ status: response.status, statusText: response.statusText });
        } else {
          return response.json();
        }
      })
      .then(data => {
        localStorage.removeItem('public-mural-shares');
        localStorage.removeItem('public-mural-shares-end');
        localStorage.removeItem('public-mural-shares-filter-gender');
        localStorage.removeItem('public-mural-shares-filter-style');

        login(data.name, data.surname, data.email, data.username, data.session, data.picture, data.gender, data.birthday, data.country, data.socials, data.referralcode, data.subscription, data.displayName);

        setLoading(false);
        if (username !== undefined && share !== undefined) {
          navigate(`/${language}/${username}/share/${share}`);
        } else {
          navigate(`/${language}/account/${data.username}/mural`);
        }
      })
      .catch(error => {
        setLoading(false);
        setErrorMessage(translations.auth_login_errorGenericMessage);
      });
  };

  return (
    <section className='auth-login-content-wrapper'>
      <div className='auth-login-content-main-title'>
        <h2>{translations.auth_login_mainTitle_signIn}</h2>
      </div>

      <div className='auth-login-wrapper-form'>
        <div className='auth-login-form-row'>
          <div className='auth-login-form-input'>
            <span>{translations.auth_login_form_emailLabel}</span>
            <input type='text' placeholder={translations.auth_login_form_emailPlaceholder} required value={email} onChange={(e) => {setEmail(e.target.value.trim()); setEmailError(false); setErrorMessage('');}} className={emailError ? ('auth-login-form-input-input-error') : ('auth-login-form-input-input')} />
          </div>
        </div>
        <div className='auth-login-form-row'>
          <div className='auth-login-form-input'>
            <span>{translations.auth_login_form_passwordLabel}</span>
            <input type='password' placeholder={translations.auth_login_form_passwordPlaceholder} value={password} onChange={(e) => {setPassword(e.target.value); setPasswordError(false); setErrorMessage('');}} className={passwordError ? ('auth-login-form-input-input-error') : ('auth-login-form-input-input')} />
          </div>
          <div className='auth-login-form-forgot-password'>
            <span>{translations.auth_login_form_lostYourPassword} <a href={`/${language}/recover-password`}>{translations.auth_login_form_recoverItNow}</a></span>
          </div>
        </div>
      </div>

      {errorMessage !== '' && (
        <div className='auth-login-errorMessage'>
          <span>{errorMessage}</span>
        </div>
      )}

      <div className='auth-login-button'>
        <LoadingButton
          buttonText={translations.auth_login_loginButton}
          loading={loading}
          actionOnClick={loginUsers}
        />
      </div>

      <div className="auth-login-separator-with-text">
        <span>{translations.auth_login_or}</span>
      </div>

      <div className='auth-login-oauth-container'>
        {/* <button> <img src='/oauth/google-logo.png' alt='Google Logo' /> <span>{translations.auth_login_oauth_continueWithGoogle}</span> </button>
        <button> <img src='/oauth/apple-logo.png' alt='Apple Logo' /> <span>{translations.auth_login_oauth_continueWithApple}</span> </button>*/}
        <a href={`/${language}/register`}>{translations.auth_login_createAccount}</a>
      </div>
    </section>
  );
}

export default AUTH_LOGIN;
