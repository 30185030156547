import React, { useEffect } from 'react';
import { useContext } from 'react';
import { LanguageContext } from '../context/LanguageContext';
import enTranslations from '../translations/en.json'
import ptTranslations from '../translations/pt.json'
import './deleteShareModal.css';

function MURAL_FILTERS({ isScrollLocked, confirm, cancel }) {
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;

    useEffect(() => {
        if (isScrollLocked) {
            // Lock the scroll
            document.body.style.overflow = 'hidden';
            document.body.style.touchAction = 'none';

            // Smoothly scroll to the top when the scroll is locked
            window.scrollTo({
                top: 0,
                behavior: 'smooth',  // Smooth scrolling
            });
        } else {
            // Unlock the scroll
            document.body.style.overflow = '';
            document.body.style.touchAction = '';
        }

        return () => {
            document.body.style.overflow = '';
            document.body.style.touchAction = '';
        };
    }, [isScrollLocked]);


    return (
        <div className='delete-share-background'>
            <div className='delete-share-background-container'>
                <h3>{translations.deleteShareModal_doYouWantToDelete}</h3>
                <span>{translations.deleteShareModal_irreversibleAction}</span>
                <div className='delete-share-buttons'>
                    <button className='delete-share-confirm-button' onClick={confirm}>{translations.deleteShareModal_confirm}</button>
                    <button className='delete-share-cancel-button' onClick={cancel}>{translations.deleteShareModal_cancel}</button>
                </div>
            </div>
        </div>
    );
}

export default MURAL_FILTERS;