import { useState, useContext } from 'react';
import { LanguageContext } from '../../context/LanguageContext';
import MainContext from '../../context/MainContext';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import LoadingButton from '../../components/loader';
import '../../style/auth/Register.css';

function AUTH_REGISTER_STEP1({ setRegisterStep, username, referralCode, displayName, profilePicture, email, setEmail, userPlan }) {
  const { language } = useContext(LanguageContext);
  const translations = language === 'en' ? enTranslations : ptTranslations;

  const [emailError, setEmailError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [loading, setLoading] = useState(false);

  const validateEmail = (inputEmail) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(inputEmail);
  };

  const sendEmailToValidateAccount = () => {

    if (!validateEmail(email)) {
      setErrorMessage(translations.auth_login_emailError);
      setEmailError(true);
      return;
    }

    setErrorMessage('');
    setEmailError(false);
    setLoading(true);

    verifyEmailAddress();

  }

  const verifyEmailAddress = () => {
    setLoading(true);
    const data = new URLSearchParams({
      email: email.toLowerCase(),
    });

    fetch(`${MainContext.apiURL}/api/v1/auth/users/register/verify-email?${data}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.ok) {
          setErrorMessage('');
          setEmailError(false);
          sendEmailToUser();
          return response.json();
        } else {
          setEmailError(true);
          setErrorMessage(translations.auth_register_userAlreadyRegistered);
          setLoading(false);
          return;
        }
      })
      .catch(error => {
        console.log(error);
        setErrorMessage(translations.auth_login_errorGenericMessage);
      });
  };

  const sendEmailToUser = () => {
    const baseURL = `${MainContext.apiURL}/api/v1/auth/users/register/send-email`;

    const formData = new URLSearchParams({
      email: email.toLowerCase(),
      referralCode: referralCode,
      subscriptionPlan: userPlan || 'essentials',
      language: language
    });

    fetch(baseURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formData.toString(),
    })
      .then(response => {
        if (!response.ok) {
          setLoading(false);
          setErrorMessage(translations.auth_login_errorGenericMessage);
          return Promise.reject({ status: response.status, statusText: response.statusText });
        } else {
          setRegisterStep(2);
          return response.json();
        }
      })
      .then(data => {
      })
      .catch(error => {
        setErrorMessage(translations.auth_login_errorGenericMessage);
        setLoading(false);
      });
  };

  return (
    <section className='auth-register-content-wrapper'>

      {referralCode !== undefined && (
        <div className='auth-register-content-username'>
          {profilePicture ? (
            <img src={`${MainContext.profilePictureURL}${profilePicture}`} alt={`Profile for user ${displayName}`} />
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <circle cx="12" cy="12" r="10" stroke="currentColor" stroke-width="1.5" />
              <path d="M7.5 17C9.8317 14.5578 14.1432 14.4428 16.5 17M14.4951 9.5C14.4951 10.8807 13.3742 12 11.9915 12C10.6089 12 9.48797 10.8807 9.48797 9.5C9.48797 8.11929 10.6089 7 11.9915 7C13.3742 7 14.4951 8.11929 14.4951 9.5Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
            </svg>
          )}
          <h3>{displayName}</h3>
          <p>@{username}</p>
        </div>
      )}

      <div className='auth-register-content-main-title'>
        <h2>{translations.auth_register_mainTitle}</h2>
        {referralCode !== undefined && (
          <p>{displayName} {translations.auth_register_referralUserMessage}</p>
        )}
      </div>

      <div className='auth-register-wrapper-form'>
        <div className='auth-register-form-row'>
          <div className='auth-register-form-input'>
            <span>{translations.auth_register_emailLabel}</span>
            <input type='text' placeholder={translations.auth_register_emailPlaceholder} value={email} onChange={(e) => { setEmail(e.target.value); setEmailError(false); setErrorMessage(''); }} className={emailError ? ('auth-register-form-input-input-error') : ('auth-register-form-input-input')} />
          </div>
        </div>
      </div>

      {errorMessage !== '' && (
        <div className='auth-login-errorMessage'>
          <span>{errorMessage}</span>
        </div>
      )}

      <div className='auth-register-button'>
        <LoadingButton
          buttonText={translations.auth_register_sendEmail}
          loading={loading}
          actionOnClick={sendEmailToValidateAccount}
        />
      </div>
    </section>
  );
}

export default AUTH_REGISTER_STEP1;
