import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { LanguageContext } from '../context/LanguageContext';
import enTranslations from '../translations/en.json'
import ptTranslations from '../translations/pt.json'
import LoadingButton from './loader';
import './mural_filters.css';

function MURAL_FILTERS({ isScrollLocked, actionOnClick, closeWithoutChanges, gender, style, audience, budget, season, sustentability, bodyType }) {
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;

    const [genderSelected, setGenderSelected] = useState(gender || '');
    const [styleSelected, setStyleSelected] = useState(style || '');
    // eslint-disable-next-line
    const [audienceSelected, setAudienceSelect] = useState(audience || '');
    // eslint-disable-next-line
    const [budgetSelected, setBudgetSelect] = useState(budget || '');
    // eslint-disable-next-line
    const [seasonSelected, setSeasonSelected] = useState(season || '');
    // eslint-disable-next-line
    const [sustentabilitySelected, setSustentabilitySelected] = useState(sustentability || '');
    // eslint-disable-next-line
    const [bodyTypeSelected, setBodyTypeSelected] = useState(bodyType || '');

    const closeFilters = () => {
        const newFilters = {
            gender: genderSelected || '',
            style: styleSelected || '',
            audience: audienceSelected || '',
            budget: budgetSelected || '',
            season: seasonSelected || '',
            sustentability: sustentabilitySelected || '',
            bodyType: bodyTypeSelected || ''
        };
        actionOnClick(newFilters);
    }

    useEffect(() => {
        if (isScrollLocked) {
            // Lock the scroll
            document.body.style.overflow = 'hidden';
            document.body.style.touchAction = 'none';

            // Smoothly scroll to the top when the scroll is locked
            window.scrollTo({
                top: 0,
                behavior: 'smooth',  // Smooth scrolling
            });
        } else {
            // Unlock the scroll
            document.body.style.overflow = '';
            document.body.style.touchAction = '';
        }

        return () => {
            document.body.style.overflow = '';
            document.body.style.touchAction = '';
        };
    }, [isScrollLocked]);

    return (
        <div className='mural-filters-background'>
            <div className='mural-filters-background-container'>

                <div className='mural-filters-title'>
                    <img src='/private/filter_icon.png' alt='' />
                    <h2>{translations.components_filters_mainTitle}</h2>
                </div>

                <div className='mural-filters-close'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" onClick={closeWithoutChanges}>
                        <path d="M19.0005 4.99988L5.00049 18.9999M5.00049 4.99988L19.0005 18.9999" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>

                <div className='mural-filter-options'>
                    <h3>{translations.components_filters_gender}</h3>
                    <div className='mural-filter-options-buttons'>
                        <button
                            className={genderSelected === 'man' ? 'mural-filter-options-buttons-active' : 'mural-filter-options-buttons-disabled'}
                            onClick={() => setGenderSelected(genderSelected === 'man' ? '' : 'man')}>
                            {translations.components_filters_genderMan}
                        </button>
                        <button
                            className={genderSelected === 'woman' ? 'mural-filter-options-buttons-active' : 'mural-filter-options-buttons-disabled'}
                            onClick={() => setGenderSelected(genderSelected === 'woman' ? '' : 'woman')}>
                            {translations.components_filters_genderWoman}
                        </button>
                    </div>
                </div>

                <div className='mural-filter-options'>
                    <h3>{translations.components_filters_style}</h3>
                    <div className='mural-filter-options-buttons'>
                        <button
                            className={styleSelected === 'casual' ? 'mural-filter-options-buttons-active' : 'mural-filter-options-buttons-disabled'}
                            onClick={() => setStyleSelected(styleSelected === 'casual' ? '' : 'casual')}>
                            {translations.components_filters_styleCasual}
                        </button>
                        <button
                            className={styleSelected === 'formal' ? 'mural-filter-options-buttons-active' : 'mural-filter-options-buttons-disabled'}
                            onClick={() => setStyleSelected(styleSelected === 'formal' ? '' : 'formal')}>
                            {translations.components_filters_styleFormal}
                        </button>
                        <button
                            className={styleSelected === 'sporty' ? 'mural-filter-options-buttons-active' : 'mural-filter-options-buttons-disabled'}
                            onClick={() => setStyleSelected(styleSelected === 'sporty' ? '' : 'sporty')}>
                            {translations.components_filters_styleSporty}
                        </button>
                        <button
                            className={styleSelected === 'elegant' ? 'mural-filter-options-buttons-active' : 'mural-filter-options-buttons-disabled'}
                            onClick={() => setStyleSelected(styleSelected === 'elegant' ? '' : 'elegant')}>
                            {translations.components_filters_styleElegant}
                        </button>
                        <button
                            className={styleSelected === 'vintage' ? 'mural-filter-options-buttons-active' : 'mural-filter-options-buttons-disabled'}
                            onClick={() => setStyleSelected(styleSelected === 'vintage' ? '' : 'vintage')}>
                            {translations.components_filters_styleVintage}
                        </button>
                    </div>
                </div>

                {/* 
                <div className='mural-filter-options'>
                    <h3>{translations.components_filters_audience}</h3>
                    <div className='mural-filter-options-buttons'>
                        <button
                            className={audienceSelected === 'oversized' ? 'mural-filter-options-buttons-active' : 'mural-filter-options-buttons-disabled'}
                            onClick={() => setAudienceSelect(audienceSelected === 'oversized' ? '' : 'oversized')}>
                            {translations.components_filters_audienceSizeXL}
                        </button>
                        <button
                            className={audienceSelected === 'children' ? 'mural-filter-options-buttons-active' : 'mural-filter-options-buttons-disabled'}
                            onClick={() => setAudienceSelect(audienceSelected === 'children' ? '' : 'children')}>
                            {translations.components_filters_audienceForChildren}
                        </button>
                        <button
                            className={audienceSelected === 'teenager' ? 'mural-filter-options-buttons-active' : 'mural-filter-options-buttons-disabled'}
                            onClick={() => setAudienceSelect(audienceSelected === 'teenager' ? '' : 'teenager')}>
                            {translations.components_filters_audienceForYouth}
                        </button>
                    </div>
                </div>

                <div className='mural-filter-options'>
                    <h3>{translations.components_filters_budget}</h3>
                    <div className='mural-filter-options-buttons'>
                        <button
                            className={budgetSelected === 'cheap' ? 'mural-filter-options-buttons-active' : 'mural-filter-options-buttons-disabled'}
                            onClick={() => setBudgetSelect(budgetSelected === 'cheap' ? '' : 'cheap')}>
                            {translations.components_filters_budgetAffordable}
                        </button>
                        <button
                            className={budgetSelected === 'luxury' ? 'mural-filter-options-buttons-active' : 'mural-filter-options-buttons-disabled'}
                            onClick={() => setBudgetSelect(budgetSelected === 'luxury' ? '' : 'luxury')}>
                            {translations.components_filters_budgetLuxury}
                        </button>
                    </div>
                </div>

                <div className='mural-filter-options'>
                    <h3>{translations.components_filters_season}</h3>
                    <div className='mural-filter-options-buttons'>
                        <button
                            className={seasonSelected === 'summer' ? 'mural-filter-options-buttons-active' : 'mural-filter-options-buttons-disabled'}
                            onClick={() => setSeasonSelected(seasonSelected === 'summer' ? '' : 'summer')}>
                            {translations.components_filters_seasonSummer}
                        </button>
                        <button
                            className={seasonSelected === 'winter' ? 'mural-filter-options-buttons-active' : 'mural-filter-options-buttons-disabled'}
                            onClick={() => setSeasonSelected(seasonSelected === 'winter' ? '' : 'winter')}>
                            {translations.components_filters_seasonWinter}
                        </button>
                    </div>
                </div>

                <div className='mural-filter-options'>
                    <h3>{translations.components_filters_sustainability}</h3>
                    <div className='mural-filter-options-buttons'>
                        <button
                            className={sustentabilitySelected === 'sustainability' ? 'mural-filter-options-buttons-active' : 'mural-filter-options-buttons-disabled'}
                            onClick={() => setSustentabilitySelected(sustentabilitySelected === 'sustainability' ? '' : 'sustainability')}>
                            {translations.components_filters_sustainabilitySustainableFashion}
                        </button>
                        <button
                            className={sustentabilitySelected === 'secondLife' ? 'mural-filter-options-buttons-active' : 'mural-filter-options-buttons-disabled'}
                            onClick={() => setSustentabilitySelected(sustentabilitySelected === 'secondLife' ? '' : 'secondLife')}>
                            {translations.components_filters_sustainabilitySecondHandPieces}
                        </button>
                    </div>
                </div>

                <div className='mural-filter-options'>
                    <h3>{translations.components_filters_bodyType}</h3>
                    <div className='mural-filter-options-buttons'>
                        <button
                            className={bodyTypeSelected === 'slimfit' ? 'mural-filter-options-buttons-active' : 'mural-filter-options-buttons-disabled'}
                            onClick={() => setBodyTypeSelected(bodyTypeSelected === 'slimfit' ? '' : 'slimfit')}>
                            {translations.components_filters_bodyTypeSlimFit}
                        </button>
                        <button
                            className={bodyTypeSelected === 'oversized' ? 'mural-filter-options-buttons-active' : 'mural-filter-options-buttons-disabled'}
                            onClick={() => setBodyTypeSelected(bodyTypeSelected === 'oversized' ? '' : 'oversized')}>
                            {translations.components_filters_bodyTypeOversizedLooks}
                        </button>
                        <button
                            className={bodyTypeSelected === 'adjusted' ? 'mural-filter-options-buttons-active' : 'mural-filter-options-buttons-disabled'}
                            onClick={() => setBodyTypeSelected(bodyTypeSelected === 'adjusted' ? '' : 'adjusted')}>
                            {translations.components_filters_bodyTypeFittedLooks}
                        </button>
                    </div>
                </div>
                */}

                <LoadingButton
                    buttonText={translations.components_filters_saveButton}
                    loading={false}
                    actionOnClick={closeFilters}
                />
            </div>
        </div>
    );
}

export default MURAL_FILTERS;