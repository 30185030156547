import { useContext } from 'react';
import { LanguageContext } from '../../../context/LanguageContext';
import enTranslations from '../../../translations/en.json'
import ptTranslations from '../../../translations/pt.json'
import '../../../style/public/landingPage/Section_AI.css';

function SECTION_AI() {
  const { language } = useContext(LanguageContext);
  const translations = language === 'en' ? enTranslations : ptTranslations;

  return (
    <section className='section-ai-wrapper'>
      <div className='section-ai-wrapper-container'>
        <h2>{translations.landingPage_sectionAI_mainTitle_RevolutionizeYourLookWithAI}</h2>
        <p>{translations.landingPage_sectionAI_mainTitle_description}</p>
        <a href={`/${language}/login`}><button>{translations.landingPage_sectionAI_cta_button_discoverMore}</button></a>
      </div>
    </section>
  );
}

export default SECTION_AI;
