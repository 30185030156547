import React, { useContext } from 'react';
import { LanguageContext } from '../../context/LanguageContext';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import '../../style/common.css';
import '../../style/auth/commonAuth.css';

const HOOKER_AUTH_BOTTOM = () => {
    const { language, toggleLanguage } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;

    return (
        <section className='auth-top-wrapper'>
            <div className='section-footer-separator' />

            <div className='section-footer-links' onClick={() => language === 'en' ? (toggleLanguage('pt')) : (toggleLanguage('en'))}>
                <div className='section-footer-links-language'>
                    <img src='/socials/globe-icon.png' alt='' />
                    <span>{translations.footer_language}</span>
                </div>
                <a href='https://app.termly.io/policy-viewer/policy.html?policyUUID=a23a4bb3-efab-4579-bbdd-649795c08d66' target="_blank" rel="noreferrer">{translations.footer_termsConditions}</a>
                <a href='https://app.termly.io/policy-viewer/policy.html?policyUUID=869d8293-6736-4428-8b29-a627ec914268' target="_blank" rel="noreferrer">{translations.footer_privacyPolicy}</a>
                <a href='https://app.termly.io/policy-viewer/policy.html?policyUUID=3ff90b67-9738-4a2c-8db8-a65415316c51' target="_blank" rel="noreferrer">{translations.footer_cookiesPolicy}</a>
                <a className="termly-display-preferences">{translations.footer_consentPreferences}</a>
            </div>
        </section>
    );
};

export default HOOKER_AUTH_BOTTOM;