import { useContext, useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { LanguageContext } from '../context/LanguageContext';
import MainContext from '../context/MainContext';
import enTranslations from '../translations/en.json'
import ptTranslations from '../translations/pt.json'
import LoadingButton from './loader';
import './pricing.css'

function SECTION_PRICING({ subActive, actionOnClick, source }) {
    const { getSessionInfo } = useAuth();
    const { language } = useContext(LanguageContext);
    const { localStoredEmail, localStoredUsername, localStoredSession } = getSessionInfo();
    const translations = language === 'en' ? enTranslations : ptTranslations;

    const plusPlanValue = '9,99';
    const plusPlanCurrency= '€';

    const [loading, setLoading] = useState(false);

    const handleClick = (plan) => {
        const planPriority = {
            none: 0,
            essentials: 1,
            plus: 2,
        };

        if (planPriority[plan] <= planPriority[subActive]) {
            return;
        }

        actionOnClick(plan);
    };

    function createSubscriptionPortal(e) {
        e.preventDefault();

        setLoading(true);

        const baseURL = `${MainContext.apiURL}/api/v1/private/users/manage/subscription`;

        const formData = new URLSearchParams({
            username: localStoredUsername,
            language: language
        });

        fetch(baseURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'email': localStoredEmail,
                'username': localStoredUsername,
                'session': localStoredSession
            },
            credentials: 'include',
            body: formData.toString(),
        })
            .then(response => {
                if (!response.ok) {
                    setLoading(false);
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                setLoading(false);
                window.location.href = data.session;
            })
            .catch(error => {
                console.error('Error creating account:', error);
            });
    }


    return (
        <div className='pricing-wrapper'>
            <div className='pricing-card-container'>
                <div className={subActive === 'essentials' ? 'pricing-card-free-active' : (subActive === 'plus' ? 'pricing-card-free-disabled' : 'pricing-card-free')} onClick={() => handleClick('essentials')}>
                    <h3>{translations.pricingComponent_planEssentials_planTitle}</h3>
                    <p>{translations.pricingComponent_planEssentials_planPrice}</p>
                    <span>{translations.pricingComponent_planEssentials_planDescription}</span>

                    {subActive === 'none' && (
                        <div className='pricing-card-arrow'>
                            <img src='/landingPage/right-arrow.png' alt='' />
                        </div>
                    )}

                    {subActive === 'essentials' && (
                        <div className='pricing-card-currentActive'>
                            <span>{translations.pricingComponent_planEssentials_planCurrentPlan}</span>
                        </div>
                    )}
                </div>

                <div className={subActive === 'plus' ? ('pricing-card-plus-active') : ('pricing-card-plus')} onClick={() => handleClick('plus')}>
                    <div className='pricing-crown'>
                        <img src='/landingPage/pricing_crown.png' alt='' />
                    </div>
                    <h3>{translations.pricingComponent_planPlus_planTitle}</h3>
                    <p>{translations.pricingComponent_planPlus_planPricing} {plusPlanValue}{plusPlanCurrency}/{translations.pricingComponent_planPlus_planMonth}</p>
                    <span>{translations.pricingComponent_planPlus_planDescription}</span>

                    {subActive === 'none' && (
                        <div className='pricing-card-arrow'>
                            <img src='/landingPage/right-arrow.png' alt='' />
                        </div>
                    )}

                    {subActive === 'essentials' && (
                        <div className='pricing-card-arrow'>
                            <span>{translations.pricingComponent_planPlus_planUpgrade}</span>
                            <img src='/landingPage/right-arrow.png' alt='' />
                        </div>
                    )}
                    {subActive === 'plus' && (
                        <div className='pricing-card-currentActive2'>
                            <span>{translations.pricingComponent_planPlus_planCurrentPlan}</span>
                        </div>
                    )}
                </div>
            </div>

            <div className='pricing-no-loyalty'>
                {source === 'internal' && subActive === 'plus' && (
                    <LoadingButton
                        buttonText={translations.pricingComponent_cta_managePlanButton}
                        actionOnClick={createSubscriptionPortal}
                        loading={loading}
                    />
                )}
                <span>{translations.pricingComponent_plansWithNoCommitment_label}</span>
            </div>
        </div>
    )
}

export default SECTION_PRICING;