import React, { useState, useContext } from 'react';
import { useAuth } from '../../context/AuthContext';
import { LanguageContext } from '../../context/LanguageContext';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import REWARD_DETAILS from '../../components/rewardsDetails'
import '../../style/private/challenges.css';

function CHALLENGES() {
    const { getSessionInfo } = useAuth();
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;
    const { localStoredUsername, localStoredReferralCode } = getSessionInfo();

    const rewards = {
        1: {
            id: '1',
            image: '/temp/2.png',
            title: {
                en: 'Hugo Boss Voucher',
                pt: 'Voucher Hugo Boss',
            },
            description: {
                en: 'Show your style and invite your friends! Complete this challenge to win a €150 voucher at Hugo Boss.',
                pt: 'Mostra o teu estilo e convida os teus amigos! Completa este desafio para ganhar um voucher de 150€ na Hugo Boss.',
            },
            category: 'clothing',
            reward: {
                en: '€150',
                pt: '150€',
            },
            typeReward: {
                en: 'Hugo Boss Voucher',
                pt: 'Voucher Hugo Boss',
            },
            requirements: {
                descriptionSharedOutfits: {
                    en: 'Share at least 10 looks with the community',
                    pt: 'Partilhar pelo menos 10 looks com a comunidade',
                },
                descriptionNewPlusUsersReferral: {
                    en: 'Refer at least 15 friends to the Plus plan',
                    pt: 'Recomendar pelo menos 15 amigos para o plano Plus',
                },
            },
            userStatus: {
                sharedOutfits: '0',
                newPlusReferrals: '0',
            },
            footNotes: {
                1: {
                    en: '* Valid for new looks shared during this period',
                    pt: '* Válido para novos looks partilhados durante este período',
                },
                2: {
                    en: '** Only valid for new users',
                    pt: '** Válido apenas para novos utilizadores',
                },
            },
            expirationDate: '2024-12-31',
            redeemable: false,
        },
        2: {
            id: '2',
            image: '/temp/3.jpg',
            title: {
                en: 'Upgrade to Plus Plan',
                pt: 'Atualiza para o Plano Plus',
            },
            description: {
                en: 'Share 10 looks with the community and refer 5 friends to the Plus Plan to earn one free month!',
                pt: 'Partilha 10 looks com a comunidade e recomenda 5 amigos para o Plano Plus para ganhar um mês gratuito!',
            },
            category: 'matchTheLook',
            reward: {
                en: '1 free month',
                pt: '1 mês gratuito',
            },
            typeReward: {
                en: 'Match the Look Upgrade',
                pt: 'Atualização Match the Look',
            },
            requirements: {
                descriptionSharedOutfits: {
                    en: 'Share at least 10 looks with the community',
                    pt: 'Partilhar pelo menos 10 looks com a comunidade',
                },
                sharedOutfits: '10',
                descriptionNewPlusUsersReferral: {
                    en: 'Refer at least 5 friends to the Plus plan',
                    pt: 'Recomendar pelo menos 5 amigos para o plano Plus',
                },
                newPlusReferrals: '5',
            },
            userStatus: {
                sharedOutfits: '0',
                newPlusReferrals: '0',
            },
            footNotes: {
                1: {
                    en: '* Valid for new looks shared during this period',
                    pt: '* Válido para novos looks partilhados durante este período',
                },
                2: {
                    en: '** Only valid for new users',
                    pt: '** Válido apenas para novos utilizadores',
                },
            },
            expirationDate: '2024-12-31',
            redeemable: false,
        },
    };

    // eslint-disable-next-line
    const [filterSelected, setFilterSelected] = useState('');
    const [rewardOpen, setRewardOpen] = useState(false);
    const [rewardIDOpen, setRewardIDOpen] = useState('');

    const filteredRewards = Object.values(rewards).filter((reward) =>
        filterSelected === '' || reward.category === filterSelected
    );

    const handleOpenOrCloseChallenge = (id) => {
        if (rewardOpen) {
            setRewardIDOpen('');
        } else {
            setRewardIDOpen(id);
        }
        setRewardOpen(!rewardOpen);
    }

    return (
        <section className='challenges-wrapper'>
            <div className='challenges-main-section'>
                <div className='challenges-main-section-title'>
                    <h2>{translations.rewards_challengesMainTitle}</h2>
                </div>
                {/* 
                <div className='challenges-main-section-myrewards'>
                    <a href='/my-rewards'>
                        <button>
                            <img src='/private/myrewards_icon.png' alt='' />
                            <span>My Rewards</span>
                        </button>
                    </a>
                </div>
                */}
            </div>

            {/* 
            <div className='challenges-filter-section'>
                <button onClick={() => {
                    if (filterSelected === 'clothes') {
                        setFilterSelected('');
                    } else {
                        setFilterSelected('clothes');
                    }
                }}
                    className={filterSelected === 'clothes' ? ('challenges-filter-section-button-active') : ('challenges-filter-section-button-disabled')}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M5.99756 12V17.6841C5.99756 19.4952 5.99756 20.4008 6.58285 20.9635C7.24789 21.6028 9.6154 21.9785 11.9925 21.9991C14.3085 22.0192 16.6337 21.7022 17.4021 20.9635C17.9874 20.4008 17.9874 19.4952 17.9874 17.6841V12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M5.72228 14.022C4.8566 13.7083 3.22971 13.4394 2.49588 12.7032C1.48592 11.69 2.13864 10.3201 3.37707 7.73891C3.93449 6.5771 5.00094 5.72243 6.24362 5.38452C6.41238 5.33863 6.55884 5.23314 6.65592 5.08755L7.93933 3.08867C7.97639 3.03309 8.02343 2.98533 8.08061 2.95084C8.65909 2.60195 10.0921 1.99998 11.9925 1.99998C13.8929 1.99998 15.2321 2.60195 15.8105 2.95084C15.8677 2.98533 15.9148 3.03309 15.9518 3.08867L17.2721 5.0687C17.3692 5.21429 17.5156 5.31978 17.6844 5.36567C18.9271 5.70358 19.9451 6.45012 20.5026 7.61193C21.8937 10.1413 22.5105 11.6707 21.5005 12.6839C20.7667 13.4201 19.1174 13.7116 18.2517 14.0253" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M6.50098 5.49998L10.7345 8.79261C11.3409 9.26419 11.644 9.49998 12.001 9.49998C12.358 9.49998 12.6611 9.26419 13.2675 8.79261L17.501 5.49998" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M9.50098 2.99998L11.001 8.99998M14.501 2.99998L13.001 8.99998" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>Clothing & Fashion</span>
                </button>

                <button onClick={() => {
                    if (filterSelected === 'media') {
                        setFilterSelected('');
                    } else {
                        setFilterSelected('media');
                    }
                }} className={filterSelected === 'media' ? ('challenges-filter-section-button-active') : ('challenges-filter-section-button-disabled')}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <circle cx="10.5" cy="10.5" r="8.5" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                        <path d="M10.4998 10.5H10.5075M14 7L13 8M8 13L7 14M14 14L13 13M8 8L7 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                        <path d="M13.5 18.5L19.3225 17.5348C20.7193 17.2917 22 18.3514 22 19.7503C22 20.9928 20.9787 22 19.7189 22H18.7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>Media and Movies</span>
                </button>

                <button onClick={() => {
                    if (filterSelected === 'matchTheLook') {
                        setFilterSelected('');
                    } else {
                        setFilterSelected('matchTheLook');
                    }
                }} className={filterSelected === 'matchTheLook' ? ('challenges-filter-section-button-active') : ('challenges-filter-section-button-disabled')}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M15.1312 2.5C14.1462 2.17555 13.0936 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 10.9548 21.8396 9.94704 21.5422 9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                        <path d="M17 12C17 14.7614 14.7614 17 12 17C9.23858 17 7 14.7614 7 12C7 9.23858 9.23858 7 12 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M19.5 4.5L12 12M19.5 4.5V2M19.5 4.5H22" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                    </svg>
                    <span>Match the Look</span>
                </button>
            </div>
            */}

            <div className="challenges-cards-section">
                {filteredRewards.length === 0 ? (
                    <p className="no-data-message">{translations.rewards_noDataAvailable}</p>
                ) : (
                    filteredRewards.map((reward) => (
                        <div
                            className="challenges-card-container"
                            key={reward.id}
                            onClick={() => handleOpenOrCloseChallenge(reward.id)}
                        >
                            <img
                                src={reward.image}
                                alt={reward.title[language]}
                                className="reward-image"
                            />
                            <h3 className="reward-title">{reward.title[language]}</h3>
                            <p className="reward-description">{reward.description[language]}</p>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                className="reward-button-action"
                            >
                                <path
                                    d="M16.4999 3.26621C17.3443 3.25421 20.1408 2.67328 20.7337 3.26621C21.3266 3.85913 20.7457 6.65559 20.7337 7.5M20.5059 3.49097L13.5021 10.4961"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M3.26637 16.5001C3.25437 17.3445 2.67344 20.141 3.26637 20.7339C3.85929 21.3268 6.65575 20.7459 7.50016 20.7339M10.502 13.4976L3.49825 20.5027"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </div>
                    ))

                )}
            </div>

            {rewardOpen && (
                <REWARD_DETAILS
                    isScrollLocked={true}
                    rewards={rewards}
                    rewardIDOpen={rewardIDOpen}
                    actionOnClick={handleOpenOrCloseChallenge}
                    localStoredUsername={localStoredUsername}
                    localStoredReferralCode={localStoredReferralCode}
                />
            )}

        </section>
    );
}

export default CHALLENGES;