import React, { useEffect } from 'react';
import { useContext } from 'react';
import { LanguageContext } from '../context/LanguageContext';
import enTranslations from '../translations/en.json'
import ptTranslations from '../translations/pt.json'
import './rewardsDetails.css';

function REWARDS_DETAILS({ isScrollLocked, actionOnClick, rewards, rewardIDOpen, localStoredUsername, localStoredReferralCode }) {
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;

    const selectedReward = rewards[rewardIDOpen];

    useEffect(() => {
        if (isScrollLocked) {
            document.body.style.overflow = 'hidden';
            document.body.style.touchAction = 'none';

            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        } else {
            // Unlock the scroll
            document.body.style.overflow = '';
            document.body.style.touchAction = '';
        }

        return () => {
            document.body.style.overflow = '';
            document.body.style.touchAction = '';
        };
    }, [isScrollLocked]);

    return (
        <div className="reward-details-background">
            <div className="reward-details-background-container">

                <div className="reward-details-title">
                    <h2>{selectedReward.title[language]}</h2>
                </div>

                <div className="reward-details-options-leaderboard">
                    <span>{translations.components_rewardDetails_dataIsUpdatedOnceADay}</span>
                </div>

                <div className="reward-details-close">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" onClick={actionOnClick}>
                        <path
                            d="M19.0005 4.99988L5.00049 18.9999M5.00049 4.99988L19.0005 18.9999"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>

                <div className="reward-details-options">
                    <img src="/private/rewards_icon.png" alt="" />
                    <h3 className="reward-details-options-reward-value">{selectedReward.reward[language]}</h3>
                    <span className="reward-details-options-reward-value-description">
                        {selectedReward.typeReward[language]}
                    </span>

                    <table className="reward-details-options-table">
                        <thead>
                            <tr>
                                <th>{translations.components_rewardDetails_requirments}</th>
                                <th>{translations.components_rewardDetails_current}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{selectedReward.requirements.descriptionSharedOutfits[language]}</td>
                                <td>{selectedReward.userStatus.sharedOutfits}</td>
                            </tr>
                            <tr>
                                <td>{selectedReward.requirements.descriptionNewPlusUsersReferral[language]}</td>
                                <td>{selectedReward.userStatus.newPlusReferrals}</td>
                            </tr>
                        </tbody>
                    </table>

                    <div className="reward-details-options-referral-link">
                        <span>{translations.components_rewardDetails_messageShare01} - <a href={`/${language}/${localStoredUsername}/referral/${localStoredReferralCode}`} target="_blank" rel="noopener noreferrer"> {translations.components_rewardDetails_messageShare02} </a> - {translations.components_rewardDetails_messageShare03} </span>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default REWARDS_DETAILS;