import { useState, useContext } from 'react';
import { LanguageContext } from '../../../context/LanguageContext';
import MainContext from '../../../context/MainContext';
import enTranslations from '../../../translations/en.json'
import ptTranslations from '../../../translations/pt.json'
import LoadingButton from '../../../components/loader';
import '../../../style/public/landingPage/Section_Contacts.css';

function SECTION_CONTACTS() {
  const [loading, setLoading] = useState(false);
  const { language } = useContext(LanguageContext);
  const translations = language === 'en' ? enTranslations : ptTranslations;

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [message, setMessage] = useState('');
  const [messageError, setMessageError] = useState(false);

  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  const isValidEmail = (t_email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(t_email);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    const validateEmail = isValidEmail(email);

    if (email === '' || name === '' || message === '' || !validateEmail) {
      if (email === '' || !validateEmail) {
        setEmailError(true);
      } else {
        setEmailError(false);
      }

      if (name === '') {
        setNameError(true);
      } else {
        setNameError(false);
      }

      if (message === '') {
        setMessageError(true);
      } else {
        setMessageError(false);
      }

      setError(translations.landingPage_sectionContacts_errorMessage)

      return;
    }
    
    setError('');
    setEmailError(false);
    setNameError(false);
    setMessageError(false);

    setLoading(true);

    const baseURL = `${MainContext.apiURL}/api/v1/public/users/contact/email`;

    const formData = new URLSearchParams({
      email: email,
      title: name,
      message: message,
    });

    fetch(baseURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formData.toString(),
    })
      .then(response => {
        setLoading(false);
        if (!response.ok) {
          return Promise.reject({ status: response.status, statusText: response.statusText });
        } else {
          setEmail('');
          setName('');
          setMessage('');
          setSuccess(true);
          setLoading(false);
          return response.json();
        }
      })
      .then(data => {

      })
      .catch(error => {
        setLoading(false);
        console.error('Error creating account:', error);

      });
  };

  return (
    <section className='section-contacts-wrapper'>

      <div className='section-contacts-wrapper-container'>
        <h2>{translations.landingPage_sectionContacts_mainTitle_contactUs}</h2>
        <p>{translations.landingPage_sectionContacts_description}</p>
      </div>

      <div className='section-contacts-wrapper-form'>
        <div className='section-contacts-form-row'>
          <div className='section-contacts-form-input'>
            <span>{translations.landingPage_sectionContacts_form_nameLabel}</span>
            <input type='text' placeholder={translations.landingPage_sectionContacts_form_yourNamePlaceholder} value={name} onChange={(e) => setName(e.target.value)} required className={emailError ? ('section-contacts-form-input-input-error') : ('section-contacts-form-input-input')} />
          </div>
          <div className='section-contacts-form-input'>
            <span>{translations.landingPage_sectionContacts_form_emailLabel}</span>
            <input type='text' placeholder={translations.landingPage_sectionContacts_form_yourEmailPlaceholder} value={email} onChange={(e) => setEmail(e.target.value)} required className={nameError ? ('section-contacts-form-input-input-error') : ('section-contacts-form-input-input')} />
          </div>
        </div>
        <div className='section-contacts-form-row'>
          <div className='section-contacts-form-input'>
            <span>{translations.landingPage_sectionContacts_form_messageLabel}</span>
            <textarea type='text' placeholder={translations.landingPage_sectionContacts_form_yourMessagePladeholder} value={message} onChange={(e) => setMessage(e.target.value)} required className={messageError ? ('section-contacts-form-input-textarea-error') : ('section-contacts-form-input-textarea')} />
          </div>
        </div>
      </div>

      {error !== '' && (
        <div className='section-contacts-form-error'>
          <span>{error}</span>
        </div>
      )}

      {success !== '' ? (
        <div className='section-contacts-form-success'>
          <span>{translations.landingPage_sectionContacts_messageSent}</span>
        </div>
      ) : (
        <div className='section-contacts-button'>
          <LoadingButton
            buttonText={translations.landingPage_sectionContacts_form_sendButton}
            loading={loading}
            actionOnClick={sendEmail}
          />
        </div>
      )}


    </section>
  );
}

export default SECTION_CONTACTS;
