import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../context/AuthContext';
import { Link } from 'react-router-dom';
import MainContext from '../../context/MainContext';
import ShareReferences from '../../components/shareRefences';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import DeleteShareModal from '../../components/deleteShareModal';
import LoadingWithText from '../../components/loadingWithText';
import '../../style/public/share.css'

function Share({ shareData, language, like, setLike, save, setSave }) {

    const [imageLoadingFromStorage, setImageLoadingFromStorage] = useState(true);

    const { isAuthenticated, getSessionInfo } = useAuth();
    const translations = language === 'en' ? enTranslations : ptTranslations;

    const { localStoredEmail, localStoredUsername, localStoredSession } = getSessionInfo();

    const [otherOptionsOpen, setOtherOptionsOpen] = useState(false);
    const otherOptionsMenuRef = useRef(null);

    const [settingsMenuOpen, setSettingsMenuOpen] = useState(false);
    const [confirmDeleteShare, setConfirmDeleteShare] = useState(false);
    const [deletingShare, setDeletingShare] = useState(false);
    const wrapperRef = useRef(null);

    const createEditShareUrl = () => {
        return `/${language}/account/${localStoredUsername}/edit/${shareData.share}`;
    };

    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setSettingsMenuOpen(false);
        }
    };

    useEffect(() => {
        if (settingsMenuOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [settingsMenuOpen]);

    const confirmToDeleteShare = () => {
        setOtherOptionsOpen(false);
        setConfirmDeleteShare(!confirmDeleteShare);
    };

    const deleteShare = () => {
        setConfirmDeleteShare(false);
        setDeletingShare(true);

        const baseURL = `${MainContext.apiURL}/api/v1/private/shares/delete/share`;

        const formData = new URLSearchParams({
            picture: shareData.share,
        });

        fetch(baseURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'email': localStoredEmail,
                'username': localStoredUsername,
                'session': localStoredSession
            },
            credentials: 'include',
            body: formData.toString(),
        })
            .then(response => {
                if (!response.ok) {
                    setDeletingShare(false);
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    const profileShares = JSON.parse(localStorage.getItem('profile-shares'));
                    const muralShares = JSON.parse(localStorage.getItem('private-mural-shares'));

                    // Remove from 'profile-shares'
                    if (profileShares) {
                        const updatedShares = profileShares.filter(entry => entry.picture !== shareData.share.picture);
                        localStorage.setItem('profile-shares', JSON.stringify(updatedShares));
                    }

                    // Remove from 'private-mural-shares'
                    if (muralShares) {
                        const updatedMuralShares = muralShares.filter(entry => entry.share !== shareData.share);
                        localStorage.setItem('private-mural-shares', JSON.stringify(updatedMuralShares));
                    }

                    // Remove the 'share_image' localStorage item if it exists
                    const shareImageKey = `share_${shareData.share}`;
                    if (localStorage.getItem(shareImageKey)) {
                        localStorage.removeItem(shareImageKey);
                    }

                    setDeletingShare(false);
                    return window.location.href = `/${language}/${localStoredUsername}`;

                    //return response.json();
                }
            })
            .catch(error => {
                console.error('Error updating share:', error);
                setDeletingShare(false);
            });
    }

    // eslint-disable-next-line
    const likeShare = (action) => {
        setLike(action);

        const baseURL = `${MainContext.apiURL}/api/v1/private/shares/like/share`;
        const pictureId = shareData.share.picture;
        const storageKey = `share_${pictureId}`;

        const formData = new URLSearchParams({
            picture: pictureId,
            action: action
        });

        fetch(baseURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'email': localStoredEmail,
                'username': localStoredUsername,
                'session': localStoredSession
            },
            credentials: 'include',
            body: formData.toString(),
        })
            .then(response => {
                if (!response.ok) {
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                const storedItem = localStorage.getItem(storageKey);
                if (storedItem) {
                    const itemData = JSON.parse(storedItem);
                    itemData.liked = action;
                    localStorage.setItem(storageKey, JSON.stringify(itemData));
                }
            })
            .catch(error => {
                console.error('Error updating share:', error);
            });
    };

    // eslint-disable-next-line
    const saveShare = (action) => {

        setSave(action);

        const baseURL = `${MainContext.apiURL}/api/v1/private/shares/save/share`;
        const pictureId = shareData.share.picture;
        const storageKey = `share_${pictureId}`;

        const formData = new URLSearchParams({
            picture: pictureId,
            action: action
        });

        fetch(baseURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'email': localStoredEmail,
                'username': localStoredUsername,
                'session': localStoredSession
            },
            credentials: 'include',
            body: formData.toString(),
        })
            .then(response => {
                if (!response.ok) {
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                } else {
                    return response.json();
                }
            })
            .then(data => {
                const storedItem = localStorage.getItem(storageKey);
                if (storedItem) {
                    const itemData = JSON.parse(storedItem);
                    itemData.saved = action;
                    localStorage.setItem(storageKey, JSON.stringify(itemData));
                }
            })
            .catch(error => {
                console.error('Error updating share save:', error);
            });
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (otherOptionsMenuRef.current && !otherOptionsMenuRef.current.contains(event.target)) {
                setOtherOptionsOpen(false);
            }
        };

        if (otherOptionsOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        // Cleanup the event listener on unmount or when otherOptionsOpen changes
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [otherOptionsOpen]);

    return (
        <div className='share-wrapper'>

            <div className='share-background-container'>

                <div className='share-image'>

                    {imageLoadingFromStorage && (
                        <div className='grid-image-loader-container'>
                            <div className="grid-image-loader"></div>
                        </div>
                    )}

                    <img
                        src={`${MainContext.storageClothesURL}${shareData.share}${shareData.extension}`}
                        alt={`${shareData.author}-${shareData.share}`}
                        onLoad={() => setImageLoadingFromStorage(false)}
                        style={{ display: imageLoadingFromStorage ? 'none' : 'block' }}
                    />
                </div>
                <div className='share-information'>
                    <ShareReferences
                        shareData={shareData}
                        language={language}
                        translations={translations}
                    />
                </div>

                <div className='share-information-author'>
                    <a href={`/${language}/${shareData.author}`}>
                        <div className='share-author'>
                            {shareData.profilePicture !== null && shareData.profilePicture !== '' ? (
                                <img src={`${MainContext.profilePictureURL}${shareData.profilePicture}`} alt={`Author ${shareData.author} profile ${shareData.profilePicture}`} />
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <circle cx="12" cy="12" r="10" stroke="currentColor" stroke-width="1.5" />
                                    <path d="M7.5 17C9.8317 14.5578 14.1432 14.4428 16.5 17M14.4951 9.5C14.4951 10.8807 13.3742 12 11.9915 12C10.6089 12 9.48797 10.8807 9.48797 9.5C9.48797 8.11929 10.6089 7 11.9915 7C13.3742 7 14.4951 8.11929 14.4951 9.5Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                                </svg>
                            )}
                            <span>@{shareData.author}</span>
                        </div>
                    </a>
                    <div className='share-options'>

                        {/* 
                        {like ? (
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" onClick={() => likeShare(false)} className='share-information-properties-container-actions-likeafter'>
                                <path d="m11.645 20.91-.007-.003-.022-.012a15.247 15.247 0 0 1-.383-.218 25.18 25.18 0 0 1-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0 1 12 5.052 5.5 5.5 0 0 1 16.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 0 1-4.244 3.17 15.247 15.247 0 0 1-.383.219l-.022.012-.007.004-.003.001a.752.752 0 0 1-.704 0l-.003-.001Z" />
                            </svg>
                        ) : (
                            <>
                                {isAuthenticated ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" onClick={() => likeShare(true)} className='share-information-properties-container-actions-likebefore'>
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z" />
                                    </svg>
                                ) : (
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className='share-information-properties-container-actions-likebefore'>
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z" />
                                    </svg>
                                )}

                            </>
                        )}
                        {save ? (
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" onClick={() => saveShare(false)} className='share-information-properties-container-actions-saveafter'>
                                <path fill-rule="evenodd" d="M6.32 2.577a49.255 49.255 0 0 1 11.36 0c1.497.174 2.57 1.46 2.57 2.93V21a.75.75 0 0 1-1.085.67L12 18.089l-7.165 3.583A.75.75 0 0 1 3.75 21V5.507c0-1.47 1.073-2.756 2.57-2.93Z" clip-rule="evenodd" />
                            </svg>
                        ) : (
                            <>
                                {isAuthenticated ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" onClick={() => saveShare(true)} className='share-information-properties-container-actions-savebefore'>
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0Z" />
                                    </svg>
                                ) : (
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className='share-information-properties-container-actions-savebefore'>
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0Z" />
                                    </svg>
                                )}

                            </>
                        )}

                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M21.0477 3.05293C18.8697 0.707363 2.48648 6.4532 2.50001 8.551C2.51535 10.9299 8.89809 11.6617 10.6672 12.1581C11.7311 12.4565 12.016 12.7625 12.2613 13.8781C13.3723 18.9305 13.9301 21.4435 15.2014 21.4996C17.2278 21.5892 23.1733 5.342 21.0477 3.05293Z" stroke="currentColor" stroke-width="1.5" />
                            <path d="M11.5 12.5L15 9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                        */}

                        {isAuthenticated && localStoredUsername === shareData.author && (
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" onClick={() => setOtherOptionsOpen(true)}>
                                <path d="M11.9959 12H12.0049" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15.9998 12H16.0088" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M7.99981 12H8.00879" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z" stroke="currentColor" stroke-width="1.5" />
                            </svg>
                        )}

                        {otherOptionsOpen && (
                            <>
                                <div className='share-options-more-backrgound' ref={otherOptionsMenuRef}>

                                    {isAuthenticated && localStoredUsername === shareData.author && (
                                        <>
                                            <Link to={createEditShareUrl()} state={{ shareData }}>
                                                <div className='share-options-more-option'>
                                                    <span>Editar</span>

                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                        <path d="M14.0737 3.88545C14.8189 3.07808 15.1915 2.6744 15.5874 2.43893C16.5427 1.87076 17.7191 1.85309 18.6904 2.39232C19.0929 2.6158 19.4769 3.00812 20.245 3.79276C21.0131 4.5774 21.3972 4.96972 21.6159 5.38093C22.1438 6.37312 22.1265 7.57479 21.5703 8.5507C21.3398 8.95516 20.9446 9.33578 20.1543 10.097L10.7506 19.1543C9.25288 20.5969 8.504 21.3182 7.56806 21.6837C6.63212 22.0493 5.6032 22.0224 3.54536 21.9686L3.26538 21.9613C2.63891 21.9449 2.32567 21.9367 2.14359 21.73C1.9615 21.5234 1.98636 21.2043 2.03608 20.5662L2.06308 20.2197C2.20301 18.4235 2.27297 17.5255 2.62371 16.7182C2.97444 15.9109 3.57944 15.2555 4.78943 13.9445L14.0737 3.88545Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                                                        <path d="M13 4L20 11" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                                                        <path d="M14 22L22 22" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                </div>
                                            </Link>

                                            <div className='share-options-more-option' onClick={confirmToDeleteShare}>
                                                <span>Apagar</span>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                    <path d="M19.5 5.5L18.8803 15.5251C18.7219 18.0864 18.6428 19.3671 18.0008 20.2879C17.6833 20.7431 17.2747 21.1273 16.8007 21.416C15.8421 22 14.559 22 11.9927 22C9.42312 22 8.1383 22 7.17905 21.4149C6.7048 21.1257 6.296 20.7408 5.97868 20.2848C5.33688 19.3626 5.25945 18.0801 5.10461 15.5152L4.5 5.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                                                    <path d="M3 5.5H21M16.0557 5.5L15.3731 4.09173C14.9196 3.15626 14.6928 2.68852 14.3017 2.39681C14.215 2.3321 14.1231 2.27454 14.027 2.2247C13.5939 2 13.0741 2 12.0345 2C10.9688 2 10.436 2 9.99568 2.23412C9.8981 2.28601 9.80498 2.3459 9.71729 2.41317C9.32164 2.7167 9.10063 3.20155 8.65861 4.17126L8.05292 5.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                                                    <path d="M9.5 16.5L9.5 10.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                                                    <path d="M14.5 16.5L14.5 10.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                                                </svg>
                                            </div>
                                        </>
                                    )}

                                    {/* 
                                    <div className='share-options-more-option'>
                                        <span>Reportar</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <path d="M5.32171 9.6829C7.73539 5.41196 8.94222 3.27648 10.5983 2.72678C11.5093 2.42437 12.4907 2.42437 13.4017 2.72678C15.0578 3.27648 16.2646 5.41196 18.6783 9.6829C21.092 13.9538 22.2988 16.0893 21.9368 17.8293C21.7376 18.7866 21.2469 19.6548 20.535 20.3097C19.241 21.5 16.8274 21.5 12 21.5C7.17265 21.5 4.75897 21.5 3.46496 20.3097C2.75308 19.6548 2.26239 18.7866 2.06322 17.8293C1.70119 16.0893 2.90803 13.9538 5.32171 9.6829Z" stroke="currentColor" stroke-width="1.5" />
                                            <path d="M11.992 16H12.001" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M12 13L12 8.99997" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </div>
                                    */}

                                </div>
                            </>

                        )}
                    </div>
                </div>

            </div>

            {confirmDeleteShare && (
                <DeleteShareModal
                    isScrollLocked={true}
                    confirm={deleteShare}
                    cancel={confirmToDeleteShare}
                />
            )}

            {deletingShare && (
                <LoadingWithText />
            )}

        </div>
    );
}

export default Share;