import { useState } from 'react';
import OverlayShare from './overlayShare';
import './grid.css';

function GRID({ shares, imageLoadingFromStorage, setImageLoadingFromStorage, MainContext }) {

    const [overlayOpen, setOverlayOpen] = useState(false);
    const [selectedShare, setSelectedShare] = useState(null);

    const openOrCloseMural = (share = null) => {
        setOverlayOpen(!overlayOpen);
        setSelectedShare(share);
    };

    console.log(shares);

    return (
        <div className={'grid-shares'}>
            {shares.map((share) => (
                <div className={'grid-shares-container'} key={share.share}>
                    <div className='grid-shares-container-share'>
                        {imageLoadingFromStorage && (
                            <div className='grid-image-loader-container'>
                                <div className="grid-image-loader"></div>
                            </div>
                        )}
                        
                        <img
                            src={`${MainContext.storageClothesURL}${share.share}${share.extension}`}
                            alt={`${share.author}-${share.share}`}
                            onLoad={() => setImageLoadingFromStorage(false)}
                            style={{ display: imageLoadingFromStorage ? 'none' : 'block' }}
                            onClick={() => openOrCloseMural(share)}
                        />
                    </div>
                </div>
            ))}

            {overlayOpen && selectedShare && (
                <OverlayShare
                    isScrollLocked={true}
                    actionOnClick={() => openOrCloseMural(null)}
                    shareData={selectedShare}
                />
            )}
        </div>
    );
}

export default GRID;
