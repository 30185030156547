import React, { useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { LanguageContext } from '../../context/LanguageContext';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import TOPSECTION from './TopSection';
import NEWSHARE from '../../pages/private/NewShare';
import UPLOADING_WITH_TEXT from '../../components/uploadingWithText';
import '../../style/common.css';
import '../../style/private/commonPrivate.css';

const HOOKER_LOGIN = () => {
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;

    const [uploadingInformation, setUploadingInformation] = useState(false);

    return (
        <>
            <section className='common-public-wrapper'>
                <Helmet>
                    <title>{translations.seo_landingPage_title}</title>
                    <script type="text/javascript">
                        {`
                    (function(c,l,a,r,i,t,y){
                        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                        })(window, document, "clarity", "script", "nm5kj82ams");
                    `}
                    </script> {/* -------- MICROSOFT CLARITY -------- */}
                    <script async src="https://www.googletagmanager.com/gtag/js?id=G-TZESFWRR8N"></script>
                    <script>
                        {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                    
                        gtag('config', 'G-TZESFWRR8N');
                    `}
                    </script> {/* -------- GOOGLE ANALYTICS -------- */}
                </Helmet>

                <div className='private-common-wrapper'>
                    <div className='private-common-top'>
                        <TOPSECTION />
                    </div>
                    <div className='private-common-content'>
                        {uploadingInformation && (
                            <UPLOADING_WITH_TEXT />
                        )}
                        <NEWSHARE
                            setUploadingInformation={setUploadingInformation}
                        />
                    </div>
                </div>
            </section>
        </>
    );
};

export default HOOKER_LOGIN;
