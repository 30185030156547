import React, { useEffect, useContext } from 'react';
import { useAuth } from '../context/AuthContext';
import { LanguageContext } from '../context/LanguageContext';
import { Helmet } from 'react-helmet';
import MainContext from '../context/MainContext';
import LOADING_WITH_TEXT from '../components/loadingWithText';

const PAYMENT_CONFIRMATION = () => {
    const { getSessionInfo } = useAuth();
    const { language } = useContext(LanguageContext);
    const { localStoredUsername, localStoredEmail, localStoredSession } = getSessionInfo();

    const paymentIntent = (localStorage.getItem('subscription-payment-intent') || null);
    const paymentIntentPlan = (localStorage.getItem('subscription-payment-intent-plan') || null);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (paymentIntent) {
            const paymentTimestamp = new Date(JSON.parse(paymentIntent).timestamp).getTime();
            const currentTime = Date.now();
            const thirtyMinutesInMillis = 30 * 60 * 1000;

            if (currentTime - paymentTimestamp <= thirtyMinutesInMillis) {
                if (paymentIntentPlan === null) {
                    localStorage.removeItem('subscription-payment-intent');
                    localStorage.removeItem('subscription-payment-intent-plan');
                    window.location.href = `/${language}/account/${localStoredUsername}`;
                }
                activateSubscription();
            } else {
                localStorage.removeItem('subscription-payment-intent');
                localStorage.removeItem('subscription-payment-intent-plan');
                if (paymentIntentPlan === 'plus') {
                    return window.location.href = `/${language}/account/${localStoredUsername}/ai/chat`;
                } else {
                    return window.location.href = `/${language}/account/${localStoredUsername}`;
                }
            }
        } else {
            localStorage.removeItem('subscription-payment-intent');
            localStorage.removeItem('subscription-payment-intent-plan');
            window.location.href = `/${language}/`;
        }

    }, []);
    /* eslint-disable react-hooks/exhaustive-deps */

    const activateSubscription = () => {

        const baseURL = `${MainContext.apiURL}/api/v1/private/users/activate/subscription`;

        const formData = new URLSearchParams({
            email: localStoredEmail,
            plan: paymentIntentPlan,
        });

        fetch(baseURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'email': localStoredEmail,
                'username': localStoredUsername,
                'session': localStoredSession
            },
            credentials: 'include',
            body: formData.toString(),
        })
            .then(response => {
                localStorage.removeItem('subscription-payment-intent');
                localStorage.removeItem('subscription-payment-intent-plan');

                if (response.status === 200) {
                    return response.json();
                } else if (!response.ok) {
                    return Promise.reject({ status: response.status, statusText: response.statusText });
                }
            })
            .then(data => {
                localStorage.setItem('subscription', data.plan);

                if (data.plan === 'plus') {
                    return window.location.href = `/${language}/account/${localStoredUsername}/ai/chat`;
                } else {
                    return window.location.href = `/${language}/account/${localStoredUsername}`;
                }
            })
            .catch(error => {
                console.error('Error fetching payment authorization:', error);
                return window.location.href = `/${language}/account/${localStoredUsername}`;
            });
    };

    return (
        <section className='payment-wrapper'>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <LOADING_WITH_TEXT />
        </section>
    );
};

export default PAYMENT_CONFIRMATION;
