import React, { useState, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { LanguageContext } from '../../context/LanguageContext';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import TOPSECTION from './TopSection';
import BOTTOMSECTION from './BottomSection';
import RECOVER_STEP1 from '../../pages/auth/RecoverPassword_Step1'
import RECOVER_STEP2 from '../../pages/auth/RecoverPassword_Step2'
import RECOVER_STEP3 from '../../pages/auth/RecoverPassword_Step3'
import RECOVER_STEP4 from '../../pages/auth/RecoverPassword_Step4'
import '../../style/common.css';
import '../../style/auth/commonAuth.css';

const HOOKER_LOGIN = () => {
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;

    const [recoverStep, setRecoverStep] = useState(1);
    const [email, setEmail] = useState('');
    const [securityToken, setSecurityToken] = useState('');

    return (
        <>
            <section className='common-public-wrapper'>
                <Helmet>
                    <title>{translations.seo_landingPage_title}</title>
                    <script type="text/javascript">
                        {`
                    (function(c,l,a,r,i,t,y){
                        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                        })(window, document, "clarity", "script", "nm5kj82ams");
                    `}
                    </script> {/* -------- MICROSOFT CLARITY -------- */}
                    <script async src="https://www.googletagmanager.com/gtag/js?id=G-TZESFWRR8N"></script>
                    <script>
                        {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                    
                        gtag('config', 'G-TZESFWRR8N');
                    `}
                    </script> {/* -------- GOOGLE ANALYTICS -------- */}
                </Helmet>

                <div className='auth-common-wrapper'>
                    <div className='auth-common-top'>
                        <TOPSECTION />
                    </div>
                    <div className='auth-common-content'>
                        {recoverStep === 1 && (
                            <RECOVER_STEP1
                                setRecoverStep={setRecoverStep}
                                email={email}
                                setEmail={setEmail}
                            />
                        )}
                        {recoverStep === 2 && (
                            <RECOVER_STEP2
                                setRecoverStep={setRecoverStep}
                                email={email}
                                setSecurityToken={setSecurityToken}
                            />
                        )}
                        {recoverStep === 3 && (
                            <RECOVER_STEP3
                                setRecoverStep={setRecoverStep}
                                email={email}
                                securityToken={securityToken}
                            />
                        )}
                        {recoverStep === 4 && (
                            <RECOVER_STEP4 />
                        )}
                    </div>
                    <div className='auth-common-footer'>
                        <BOTTOMSECTION />
                    </div>
                </div>
            </section>
        </>
    );
};

export default HOOKER_LOGIN;
