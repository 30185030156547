import { useState, useContext } from 'react';
import { useAuth } from '../../context/AuthContext';
import { LanguageContext } from '../../context/LanguageContext';
import MainContext from '../../context/MainContext';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import LoadingWithText from '../../components/loadingWithText';
import Pricing from '../../components/pricing';
import '../../style/private/MyProfile.css';

function PRIVATE_MY_PROFILE() {
  const { getSessionInfo } = useAuth();
  const { localStoredEmail, localStoredUsername, localStoredSession, localStoredSubscription } = getSessionInfo();
  const { language } = useContext(LanguageContext);
  const translations = language === 'en' ? enTranslations : ptTranslations;

  const [creatingPaymentSession, setCreatingPaymentSession] = useState(false);

  const createCheckoutSession = async () => {
      setCreatingPaymentSession(true);
      const baseURL = `${MainContext.apiURL}/api/v1/private/users/pay/subscription`;
  
      const formData = new URLSearchParams({
        email: localStoredEmail,
        username: localStoredUsername,
        page: 'none',
        language: language,
        plan: 'plus'
      });
  
      fetch(baseURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'email': localStoredEmail,
          'username': localStoredUsername,
          'session': localStoredSession
        },
        credentials: 'include',
        body: formData.toString(),
      })
        .then(response => {
          if (!response.ok) {
            setCreatingPaymentSession(false);
            return Promise.reject({ status: response.status, statusText: response.statusText });
          } else {
            return response.json();
          }
        })
        .then(data => {
          const currentTimestampUTC = new Date().toISOString();
  
          const paymentIntentObject = {
            timestamp: currentTimestampUTC
          };
  
          localStorage.setItem('subscription-payment-intent', JSON.stringify(paymentIntentObject));
          localStorage.setItem('subscription-payment-intent-plan', 'plus');
  
          setCreatingPaymentSession(false);
  
          window.location.href = data.session;
        })
        .catch(error => {
          console.error('Error activating account:', error);
          setCreatingPaymentSession(false);
        });
    };

  return (
    <section className='private-my-profile-wrapper'>
      <div className='private-my-profile-title'>
        <h2>{translations.private_mySubscription}</h2>
      </div>

      <Pricing
        source={'internal'}
        subActive={localStoredSubscription}
        actionOnClick={createCheckoutSession}
      />

      {creatingPaymentSession && (
        <LoadingWithText />
      )}

    </section>
  );
}

export default PRIVATE_MY_PROFILE;