import { useState, useEffect, useRef, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { Link } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { LanguageContext } from '../../context/LanguageContext';
import MainContext from '../../context/MainContext';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import '../../style/auth/Register.css';

function AUTH_REGISTER_STEP4() {
  const { language } = useContext(LanguageContext);
  const translations = language === 'en' ? enTranslations : ptTranslations;

  return (
    <section className='auth-register-content-wrapper'>
      <div className='auth-register-content-main-title'>
        <h2>{translations.auth_recoverPassword_passwordChangedTitle}</h2>
        <p>{translations.auth_recoverPassword_passwordChangeDescription}</p>
      </div>

      <div className='auth-register-button'>
        <a href={`/${language}/login`}><button>{translations.auth_recoverPassword_buttonLogin}</button></a>
      </div>
    </section>
  );
}

export default AUTH_REGISTER_STEP4;
