import { useContext } from 'react';
import { LanguageContext } from '../../context/LanguageContext';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import '../../style/auth/Register.css';

function AUTH_REGISTER_STEP7() {
  const { language } = useContext(LanguageContext);
  const translations = language === 'en' ? enTranslations : ptTranslations;

  return (
    <section className='auth-register-content-wrapper'>
      <div className='auth-register-content-main-title'>
        <h2>{translations.auth_register_finalMessage_title}</h2>
        <p>{translations.auth_register_finalMessage_description}</p>
      </div>

      <div className='auth-register-button'>
        <a href={`/${language}/login`}><button>{translations.auth_login_loginButton}</button></a>
      </div>
    </section>
  );
}

export default AUTH_REGISTER_STEP7;
