import { useState, useContext } from 'react';
import { LanguageContext } from '../../context/LanguageContext';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import Pricing from '../../components/pricing';
import '../../style/auth/Register.css';

function AUTH_REGISTER_STEP6({ setRegisterStep, generateSecureLinkForPayment }) {
  const { language } = useContext(LanguageContext);
  const translations = language === 'en' ? enTranslations : ptTranslations;

  const [loading, setLoading] = useState(false);

  const handleClickStarted = (plan) => {
    if (plan === 'essentials') {
      return setRegisterStep(7);
    } else if (plan === 'plus') {
      setLoading(true);
      generateSecureLinkForPayment('plus');
    }
  };

  return (
    <section className='auth-register-content-wrapper'>
      <div className='auth-register-content-main-title'>
        <h2>{translations.auth_register_mainTitle}</h2>
      </div>

      {loading ? (
        <div className='auth-register-content-loading'>
          <svg xmlns="http://www.w3.org/2000/svg" height="128px" width="128px" viewBox="0 0 128 128" class="pl">
            <defs>
              <linearGradient y2="1" x2="0" y1="0" x1="0" id="pl-grad">
                <stop stop-color="hsl(193,90%,55%)" offset="0%"></stop>
                <stop stop-color="hsl(223,90%,55%)" offset="100%"></stop>
              </linearGradient>
            </defs>
            <circle stroke-linecap="round" stroke-width="16" stroke="hsla(0,10%,10%,0.1)" fill="none" cy="64" cx="64" r="56" class="pl__ring"></circle>
            <path stroke-dashoffset="10" stroke-dasharray="44 1111" stroke-linejoin="round" stroke-linecap="round" stroke-width="16" stroke="url(#pl-grad)" fill="none" d="M92,15.492S78.194,4.967,66.743,16.887c-17.231,17.938-28.26,96.974-28.26,96.974L119.85,59.892l-99-31.588,57.528,89.832L97.8,19.349,13.636,88.51l89.012,16.015S81.908,38.332,66.1,22.337C50.114,6.156,36,15.492,36,15.492a56,56,0,1,0,56,0Z" class="pl__worm"></path>
          </svg>

          <div class="auth-register-content-loading-text">
            <p>{translations.auth_register_loading}</p>
          </div>

        </div>
      ) : (
        <Pricing
          subActive={'none'}
          actionOnClick={handleClickStarted}
        />
      )}

    </section>
  );
}

export default AUTH_REGISTER_STEP6;
