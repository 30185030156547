import {useContext } from 'react';
import { LanguageContext } from '../../../context/LanguageContext';
import enTranslations from '../../../translations/en.json'
import ptTranslations from '../../../translations/pt.json'
import '../../../style/public/landingPage/Section_Mural.css';

function SECTION_MURAL() {
  const { language } = useContext(LanguageContext);
  const translations = language === 'en' ? enTranslations : ptTranslations;

  return (
    <section className='section-mural-wrapper'>
      <div className='section-mural-wrapper-container'>
        <h2>{translations.landingPage_sectionMural_mainTitle_YourStyleInOnePlace}</h2>
        <p>{translations.landingPage_sectionMural_mainTitle_description}</p>
        <a href={`/${language}/login`}><button>{translations.landingPage_sectionMural_cta_button_discoverMore}</button></a>
      </div>
      <div className='section-mural-wrapper-images'>
        <img src='/landingPage/demo_mural_02.png' alt='' className='section-mural-wrapper-images-blurred' />
        <img src='/landingPage/demo_mural_01.png' alt='' className='section-mural-wrapper-images-focus' />
        <img src='/landingPage/demo_mural_03.png' alt='' className='section-mural-wrapper-images-blurred' />
      </div>
    </section>
  );
}

export default SECTION_MURAL;
