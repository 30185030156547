import React, { useContext } from 'react';
import { LanguageContext } from '../../context/LanguageContext';
import '../../style/common.css';
import '../../style/auth/commonAuth.css';

const HOOKER_AUTH_TOP = () => {
    const { language } = useContext(LanguageContext);

    return (
        <section className='auth-top-wrapper'>
            <a href={`/${language}`}><h1 translate='no'>Match the Look</h1></a>
        </section>
    );
};

export default HOOKER_AUTH_TOP;
