import './shareReferences.css';

const translationsLocal = {
    en: {
        // Clothing
        jacket: 'Jacket',
        blazer: 'Blazer',
        vest: 'Vest',
        sweater: 'Sweater',
        blouse: 'Blouse',
        dress: 'Dress',
        polo: 'Polo',
        shirt: 'Shirt',
        tshirt: 'T-shirt',
        pants: 'Pants',
        jeans: 'Jeans',
        shorts: 'Shorts',
        skirt: 'Skirt',
        pijama: 'Pajama',
        swimwear: 'Swimwear',
        boxers: 'Boxers',
        bra: 'Bra',
        panties: 'Panties',
        socks: 'Socks',
        other: 'Other',

        // Accessories
        cap: 'Cap',
        hat: 'Hat',
        gloves: 'Gloves',
        scarf: 'Scarf',
        watch: 'Watch',
        belt: 'Belt',
        handbag: 'Handbag',
        necklace: 'Necklace',
        ring: 'Ring',
        earrings: 'Earrings',
        bag: 'Bag',
        bagpack: 'Backpack',
        tie: 'Tie',
        bowtie: 'Bowtie',
        bracelet: 'Bracelet',
        wallet: 'Wallet',
        glasses: 'Glasses',

        // Footwear
        shoes: 'Shoes',
        sneakers: 'Sneakers',
        sandals: 'Sandals',
        mocassins: 'Mocassins',
        boots: 'Boots',
        ankleboots: 'Ankle Boots',
        highheels: 'High Heels',
        elegantshoes: 'Elegant Shoes',
        flipflop: 'Flip-flops',
        slippers: 'Slippers'
    },

    pt: {
        // Clothing
        jacket: 'Casaco',
        blazer: 'Blazer',
        vest: 'Colete',
        sweater: 'Camisola',
        blouse: 'Blusa',
        dress: 'Vestido',
        polo: 'Polo',
        shirt: 'Camisa',
        tshirt: 'T-shirt',
        pants: 'Calças',
        jeans: 'Calças de Ganga',
        shorts: 'Calções',
        skirt: 'Saia',
        pijama: 'Pijama',
        swimwear: 'Fato de banho',
        boxers: 'Boxers',
        bra: 'Sutiã',
        panties: 'Cuecas',
        socks: 'Meias',
        other: 'Outro',

        // Accessories
        cap: 'Boné',
        hat: 'Chapéu',
        gloves: 'Luvas',
        scarf: 'Cachecol',
        watch: 'Relógio',
        belt: 'Cinto',
        handbag: 'Mala',
        necklace: 'Colar',
        ring: 'Anel',
        earrings: 'Brincos',
        bag: 'Saco',
        bagpack: 'Mochila',
        tie: 'Gravata',
        bowtie: 'Laço',
        bracelet: 'Pulseira',
        wallet: 'Carteira',
        glasses: 'Óculos',

        // Footwear
        shoes: 'Sapatos',
        sneakers: 'Ténis',
        sandals: 'Sandálias',
        mocassins: 'Mocassins',
        boots: 'Botas',
        ankleboots: 'Botins',
        highheels: 'Saltos Altos',
        elegantshoes: 'Sapatos Elegantes',
        flipflop: 'Chinelos',
        slippers: 'Pantufas'
    }
};

function SHARE_REFERENCES({ shareData, language, translations }) {

    return (
        <div className='share-references-wrapper'>

            <div className='shares-references-wrapper-container'>
                <h2 className='share-references-title'>{translations.private_newShare_referencesForShare}</h2>

                {shareData.clothes.count > 0 && (
                    <>
                        <h3 className='share-references-links-title'>{translations.private_newShare_clothes}</h3>
                        <div className='share-references-links'>
                            {Object.entries(shareData.clothes.items).map(([name, link], index) => (
                                link !== '' && (
                                    <a href={link.startsWith('http://') || link.startsWith('https://') ? link : `https://${link}`} target="_blank" rel="noopener noreferrer" key={index}>
                                        <img src={`/references/icon-${name}.png`} alt={name} />
                                        <span>{translationsLocal[language][name]}</span>
                                    </a>
                                )
                            ))}
                        </div>
                    </>
                )}

                {shareData.accessories.count > 0 && (
                    <>
                        <h3 className='share-references-links-title'>{translations.private_newShare_accessories}</h3>
                        <div className='share-references-links'>
                            {Object.entries(shareData.accessories.items).map(([name, link], index) => (
                                link !== '' && (
                                    <a href={link.startsWith('http://') || link.startsWith('https://') ? link : `https://${link}`} target="_blank" rel="noopener noreferrer" key={index}>
                                        <img src={`/references/icon-${name}.png`} alt={name} />
                                        <span>{translationsLocal[language][name]}</span>
                                    </a>
                                )
                            ))}
                        </div>
                    </>
                )}

                {shareData.footwear.count > 0 && (
                    <>
                        <h3 className='share-references-links-title'>{translations.private_newShare_footwear}</h3>
                        <div className='share-references-links'>
                            {Object.entries(shareData.footwear.items).map(([name, link], index) => (
                                link !== '' && (
                                    <a href={link.startsWith('http://') || link.startsWith('https://') ? link : `https://${link}`} target="_blank" rel="noopener noreferrer" key={index}>
                                        <img src={`/references/icon-${name}.png`} alt={name} />
                                        <span>{translationsLocal[language][name]}</span>
                                    </a>
                                )
                            ))}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default SHARE_REFERENCES