import React, { createContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const location = useLocation();

  const getLanguageFromPath = (pathname) => {
    localStorage.setItem('language', pathname.startsWith('/pt') ? 'pt' : pathname.startsWith('/en') ? 'en' : null);
    return pathname.startsWith('/pt') ? 'pt' : pathname.startsWith('/en') ? 'en' : null;
  };

  const [language, setLanguage] = useState(getLanguageFromPath(location.pathname));
  const [userCountry, setUserCountry] = useState(null);
  const [loading, setLoading] = useState(true);

  const browserLanguage = (navigator.language || navigator.userLanguage || '').toLowerCase();

  const fetchUserCountry = async () => {
    try {
      // Get the user's IP address
      const ipResponse = await fetch('https://api.ipify.org?format=json');
      const { ip } = await ipResponse.json();

      // Use the IP to fetch geolocation data
      const geoResponse = await fetch(`http://ip-api.com/json/${ip}`);
      const { countryCode } = await geoResponse.json();

      // Check if the country is Portugal (PT) or Brazil (BR)
      if (countryCode === 'PT' || countryCode === 'BR') {
        localStorage.setItem('language', 'pt');
        setUserCountry('pt');
      } else {
        localStorage.setItem('language', 'en');
        setUserCountry('en');
      }
    } catch (error) {
      // Default to English if there is an error
      localStorage.setItem('language', 'en');
      setUserCountry('en');
    } finally {
      setLoading(false); // Ensure loading is stopped after the operation
    }
  };


  const setLanguageBasedOnCountry = (country) => {
    let t_language;

    if (country === 'pt') {
      t_language = 'pt';
    } else {
      t_language = 'en'
    }
    localStorage.setItem('language', t_language);
    return t_language;
  };

  const toggleLanguage = (v_language) => {
    const currentPath = location.pathname.replace(/^\/(en|pt)/, '');

    let newPath;
    if (v_language === 'pt') {
      localStorage.setItem('language', 'pt');
      newPath = `/pt${currentPath}`
    } else {
      localStorage.setItem('language', 'en');
      newPath = `/en${currentPath}`
    }
    window.location.href = newPath;
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (language === null) {
      fetchUserCountry();
    }
  }, [language]);
  /* eslint-disable react-hooks/exhaustive-deps */

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const currentLanguage = getLanguageFromPath(location.pathname);
    const storedLanguage = localStorage.getItem('language');

    if (storedLanguage === 'en' || storedLanguage === 'pt') {
      if (currentLanguage !== storedLanguage) {
        const currentPath = location.pathname.replace(/^\/(en|pt)/, '');
        const newPath = `/${storedLanguage}${currentPath}`;
        window.location.href = newPath;
      } else {
        setLanguage(storedLanguage);
      }
    } else {
      // Fallback to the original logic if no valid storedLanguage
      if (!currentLanguage && userCountry && !loading) {
        const newLanguage = setLanguageBasedOnCountry(userCountry);
        const currentPath = location.pathname.replace(/^\/(en|pt)/, '');
        const newPath = `/${newLanguage}${currentPath}`;
        window.location.href = newPath;
      }

      if (currentLanguage && currentLanguage !== language) {
        localStorage.setItem('language', currentLanguage);
        setLanguage(currentLanguage);
      }
    }
  }, [location.pathname, userCountry, language, loading]);
  /* eslint-disable react-hooks/exhaustive-deps */


  return (
    <LanguageContext.Provider value={{ language, toggleLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
