import { useState, useContext } from 'react';
import { useAuth } from '../../context/AuthContext';
import { LanguageContext } from '../../context/LanguageContext';
import MainContext from '../../context/MainContext';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import LoadingButton from '../../components/loader';
import '../../style/private/LinkSocialMedia.css';

function PRIVATE_MY_PROFILE() {
  const { getSessionInfo } = useAuth();
  const { localStoredEmail, localStoredUsername, localStoredSession, localStoredSocials } = getSessionInfo();
  const { language } = useContext(LanguageContext);
  const translations = language === 'en' ? enTranslations : ptTranslations;

  const parsedSocials = JSON.parse(localStoredSocials);
  const [facebook, setFacebook] = useState(parsedSocials?.facebook);
  const [instagram, setInstagram] = useState(parsedSocials?.instagram);
  const [linkedin, setLinkedin] = useState(parsedSocials?.linkedin);
  const [tiktok, setTikTok] = useState(parsedSocials?.tiktok);
  const [otherLink, setOtherLink] = useState(parsedSocials?.other);

  const [loading, setLoading] = useState(false);
  const [successSocials, setSuccessSocials] = useState('');
  const [errorSocials, setErrorSocials] = useState('');

  const updateUserSocials = (e) => {
    e.preventDefault();
    setLoading(true);

    const baseURL = `${MainContext.apiURL}/api/v1/private/users/update/socials`;

    const formData = new URLSearchParams({
      facebook: facebook,
      instagram: instagram,
      linkedin: linkedin,
      tiktok: tiktok,
      other: otherLink,
    });

    fetch(baseURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'email': localStoredEmail,
        'username': localStoredUsername,
        'session': localStoredSession
      },
      credentials: 'include',
      body: formData.toString(),
    })
      .then(response => {
        if (!response.ok) {
          setLoading(false);
          setErrorSocials(translations.changeUsername_error_somethingWentWrong);
          window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
          return Promise.reject({ status: response.status, statusText: response.statusText });
        } else {
          const socials = { facebook, instagram, linkedin, tiktok, otherLink };
          localStorage.setItem('socials', JSON.stringify(socials));
          return response.json();
        }
      })
      .then(data => {
        setLoading(false);
        setSuccessSocials(translations.myProfile_updatedWithSuccess);
        window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
      })
      .catch(error => {
        console.error('Error creating account:', error);

      });
  };

  return (
    <section className='private-my-profile-wrapper'>
      <div className='private-my-profile-title'>
        <h2>{translations.private_linkSocialMedia_mainTitle}</h2>
      </div>
      <div className='private-my-profile-wrapper-form'>
        <div className='private-my-profile-form-row-2'>
          <div className='private-my-profile-form-input'>
            <span>Facebook</span>
            <input type='text' placeholder='Facebook URL' value={facebook} onChange={(e) => setFacebook(e.target.value)} />
          </div>
        </div>
        <div className='private-my-profile-form-row-2'>
          <div className='private-my-profile-form-input'>
            <span>Instagram</span>
            <input type='text' placeholder='Instagram URL' value={instagram} onChange={(e) => setInstagram(e.target.value)} />
          </div>
        </div>
        <div className='private-my-profile-form-row-2'>
          <div className='private-my-profile-form-input'>
            <span>Linkedin</span>
            <input type='text' placeholder='Linkedin URL' value={linkedin} onChange={(e) => setLinkedin(e.target.value)} />
          </div>
        </div>
        <div className='private-my-profile-form-row-2'>
          <div className='private-my-profile-form-input'>
            <span>Tik-Tok</span>
            <input type='text' placeholder='Tik-Tok URL' value={tiktok} onChange={(e) => setTikTok(e.target.value)} />
          </div>
        </div>
        <div className='private-my-profile-form-row-2'>
          <div className='private-my-profile-form-input'>
            <span>{translations.private_linkSocialMedia_other}</span>
            <input type='text' placeholder={translations.private_linkSocialMedia_otherLinkURL} value={otherLink} onChange={(e) => setOtherLink(e.target.value)} />
          </div>
        </div>
      </div>

      {errorSocials !== '' && (
        <div className='auth-login-errorMessage'>
          <span>{errorSocials}</span>
        </div>
      )}

      {successSocials !== '' && (
        <div className='auth-login-successMessage'>
          <span>{successSocials}</span>
        </div>
      )}

      <div className='private-my-profile-load-more'>
        <LoadingButton
          buttonText={translations.private_linkSocialMedia_updateData}
          actionOnClick={updateUserSocials}
          loading={loading}
        />
      </div>
    </section>
  );
}

export default PRIVATE_MY_PROFILE;