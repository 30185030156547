import { useState, useContext } from 'react';
import { LanguageContext } from '../../context/LanguageContext';
import MainContext from '../../context/MainContext';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import LoadingButton from '../../components/loader';
import '../../style/auth/Register.css';

function AUTH_REGISTER_STEP4({ setRegisterStep, setDisplayName, setUsername }) {
  const { language } = useContext(LanguageContext);
  const translations = language === 'en' ? enTranslations : ptTranslations;

  const [loading, setLoading] = useState(false);
  const [t_displayName, t_setDisplayName] = useState('');
  const [displayNameError, setDisplayNameError] = useState(false);
  const [t_username, t_setUsername] = useState('');
  const [usernameError, setUsernameError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const validateUsername = () => {
    if (t_displayName === '' && t_username === '') {
      setErrorMessage(translations.auth_register_errorMessage);
      setDisplayNameError(true);
      setUsernameError(true);
      return;
    } else if (t_displayName === '') {
      setDisplayNameError(true);
      setErrorMessage(translations.auth_register_errorMessage);
      return;
    } else if (t_username === '') {
      setUsernameError(true);
      setErrorMessage(translations.auth_register_errorMessage);
      return;
    }

    setDisplayNameError(false);
    setUsernameError(false);
    setErrorMessage('');
    setLoading(true);

    const data = new URLSearchParams({
      username: t_username.toLowerCase(),
    });

    fetch(`${MainContext.apiURL}/api/v1/auth/users/register/verify-username?${data}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.ok) {
          setErrorMessage('');
          setUsernameError(false);
          setDisplayNameError(false);
          setDisplayName(t_displayName);
          setUsername(t_username.toLowerCase());
          setRegisterStep(5);
          return response.json();
        } else {
          setUsernameError(true);
          setErrorMessage(translations.auth_register_usernameAlreadyRegistered);
          setLoading(false);
          return;
        }
      })
      .catch(error => {
        console.log(error);
        setErrorMessage(translations.auth_login_errorGenericMessage);
      });
  }

  return (
    <section className='auth-register-content-wrapper'>
      <div className='auth-register-content-main-title'>
        <h2>{translations.auth_register_mainTitle}</h2>
      </div>

      <div className='auth-register-wrapper-form'>
        <div className='auth-register-form-row'>
          <div className='auth-register-form-input'>
            <span>{translations.auth_register_displayNameLabel}</span>
            <input type='text' placeholder={translations.auth_register_displayNameLabel} value={t_displayName} onChange={(e) => { t_setDisplayName(e.target.value); setDisplayNameError(false); setErrorMessage(''); }} className={displayNameError ? ('auth-register-form-input-input-error') : ('auth-register-form-input-input')} />
          </div>
        </div>
        <div className='auth-register-form-row'>
          <div className='auth-register-form-input'>
            <span>{translations.auth_register_usernameLabel}</span>
            <input type='text' placeholder={translations.auth_register_usernamePlaceholder} value={t_username} onChange={(e) => { t_setUsername(e.target.value); setUsernameError(false); setErrorMessage(''); }} className={usernameError ? ('auth-register-form-input-input-error') : ('auth-register-form-input-input')} />
          </div>
        </div>
      </div>


      {errorMessage !== '' && (
        <div className='auth-login-errorMessage'>
          <span>{errorMessage}</span>
        </div>
      )}

      <div className='auth-register-button'>
        <LoadingButton
          buttonText={translations.auth_register_nextStep}
          loading={loading}
          actionOnClick={validateUsername}
        />
      </div>
    </section>
  );
}

export default AUTH_REGISTER_STEP4;
