import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { LanguageContext } from '../context/LanguageContext';
import enTranslations from '../translations/en.json'
import ptTranslations from '../translations/pt.json'
import LoadingButton from './loader';
import './newReference.css';

function MURAL_FILTERS({ isScrollLocked, actionOnClick, closing, image, title, value }) {
    const { language } = useContext(LanguageContext);
    const translations = language === 'en' ? enTranslations : ptTranslations;

    const [loading, setLoading] = useState(false);

    const [url, setUrl] = useState(value || '');
    const [isValid, setIsValid] = useState(true);

    const validateUrl = (input) => {
        // Regex pattern for URL validation
        const urlPattern = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/.*)?$/;
        return urlPattern.test(input);
    };

    const handleChange = (e) => {
        const input = e.target.value;
        setUrl(input);
        if(input === '') {
            setIsValid(true);
        } else {
            setIsValid(validateUrl(input));
        }
    };

    const handleSave = () => {
        actionOnClick(image, url);
    };

    useEffect(() => {
        if (isScrollLocked) {
            // Lock the scroll
            document.body.style.overflow = 'hidden';
            document.body.style.touchAction = 'none';

            // Smoothly scroll to the top when the scroll is locked
            window.scrollTo({
                top: 0,
                behavior: 'smooth',  // Smooth scrolling
            });
        } else {
            // Unlock the scroll
            document.body.style.overflow = '';
            document.body.style.touchAction = '';
        }

        return () => {
            document.body.style.overflow = '';
            document.body.style.touchAction = '';
        };
    }, [isScrollLocked]);

    return (
        <div className='new-reference-background'>
            <div className='new-reference-background-container'>

                <div className='new-reference-title'>
                    <img src='/private/new-reference.png' alt='' />
                    <h2>{translations.components_newReference_mainTitle}</h2>
                </div>

                <div className='new-reference-close'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" onClick={closing}>
                        <path d="M19.0005 4.99988L5.00049 18.9999M5.00049 4.99988L19.0005 18.9999" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>

                <div className='new-reference-options'>
                    <img src={`/references/icon-${image}.png`} alt={image} />
                    <h2>{title}</h2>
                    <div className='new-reference-options-textarea'>
                        <span>{translations.components_newReference_description}</span>
                        <textarea placeholder={translations.components_newReference_linkHerePlaceholder} value={url} onChange={handleChange} className={isValid ? ('new-reference-options-textarea-valid') : ('new-reference-options-textarea-invalid')} />
                    </div>
                </div>

                {isValid ? (
                    <LoadingButton
                        buttonText={translations.components_newReference_saveButton}
                        loading={loading}
                        actionOnClick={handleSave}
                    />
                ) : (
                    <div className='new-reference-button-save'>
                        <button disabled={isValid}>
                            {translations.components_newReference_saveButton}
                        </button>
                    </div>
                )}



            </div>
        </div>
    );
}

export default MURAL_FILTERS;