import { useState, useEffect, useRef, useContext } from 'react';
import { useAuth } from '../../context/AuthContext';
import { LanguageContext } from '../../context/LanguageContext';
import MainContext from '../../context/MainContext';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import LoadingButton from '../../components/loader';
import '../../style/private/MyProfile.css';

function PRIVATE_MY_PROFILE() {
  const { getSessionInfo } = useAuth();
  const { language } = useContext(LanguageContext);
  const translations = language === 'en' ? enTranslations : ptTranslations;
  const { localStoredEmail, localStoredUsername, localStoredSession, localStoredName, localStoredSurname, localStoredGender, localStoredBirthday, localStoredCountry } = getSessionInfo();

  const [firstName, setFirstName] = useState(localStoredName);
  const [lastName, setLastName] = useState(localStoredSurname);
  const [gender, setGender] = useState(localStoredGender);
  const [birthday, setBirthday] = useState(localStoredBirthday);
  const [country, setCountry] = useState(localStoredCountry);

  const [countryOptions, setCountryOptions] = useState([]);
  const previousValueRef = useRef('');

  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [genderError, setGenderError] = useState(false);
  const [birthdayError, setBirthdayError] = useState(false);
  const [countryError, setCountryError] = useState(false);

  const [loadingUpdating, setLoadingUpating] = useState(false);

  const [successUpdate, setSuccessUpdate] = useState('');
  const [errorUpdate, setErrorUpdate] = useState('');

  const handleChangeBirthday = (e) => {
    const inputValue = e.target.value;
    setBirthdayError(false);

    const formattedDate = formatDate(inputValue);
    const [day, month, year] = formattedDate.split('-').map(Number);

    const isFourDigitYear = year && year.toString().length === 4;

    setBirthday(formattedDate);
    previousValueRef.current = formattedDate;

    const isValidDate =
      (day >= 1 && day <= 31) &&
      (month >= 1 && month <= 12) &&
      isFourDigitYear;

    if (!isValidDate) {
    }
  };

  const formatDate = (value) => {
    let cleaned = value.replace(/\D/g, '');

    if (cleaned.length >= 3 && cleaned.length <= 4) {
      cleaned = cleaned.slice(0, 2) + '-' + cleaned.slice(2);
    } else if (cleaned.length >= 5) {
      cleaned = cleaned.slice(0, 2) + '-' + cleaned.slice(2, 4) + '-' + cleaned.slice(4);
    }

    return cleaned.slice(0, 10); // Limit to DD-MM-YYYY format
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getCountryList();
  }, []);
  /* eslint-disable react-hooks/exhaustive-deps */

  const getCountryList = () => {
    const data = new URLSearchParams({
      language: language
    });

    fetch(`${MainContext.apiURL}/api/v1/auth/users/register/fetch-countries?${data}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (!response.ok) {
          return Promise.reject({ status: response.status, statusText: response.statusText });
        } else {
          return response.json();
        }
      })
      .then(data => {
        setCountryOptions(data.countries);
      })
      .catch(error => {

      });
  };

  const updateUserInformation = () => {
    if (!firstName || !lastName || !gender || !birthday || !country) {
      if (!firstName || firstName === '') {
        setFirstNameError(true);
      }

      if (!lastName || lastName === '') {
        setLastNameError(true);
      }

      if (!gender || gender === '') {
        setGenderError(true);
      }

      if (!birthday || birthday === '') {
        setBirthdayError(true);
      }

      if (!country || country === '') {
        setCountryError(true);
      }
    }
    setFirstNameError(false);
    setLastNameError(false);
    setGenderError(false);
    setBirthdayError(false);
    setCountryError(false);

    setLoadingUpating(true);

    const baseURL = `${MainContext.apiURL}/api/v1/private/users/update/information`;

    const formData = new URLSearchParams({
      name: firstName,
      surname: lastName,
      gender: gender,
      birthday: birthday,
      country: country
    });

    fetch(baseURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'email': localStoredEmail,
        'username': localStoredUsername,
        'session': localStoredSession
      },
      credentials: 'include',
      body: formData.toString(),
    })
      .then(response => {
        if (!response.ok) {
          setLoadingUpating(false);
          setErrorUpdate(translations.changeUsername_error_somethingWentWrong);
          return Promise.reject({ status: response.status, statusText: response.statusText });
        } else {
          return response.json();
        }
      })
      .then(data => {
        localStorage.setItem('name', firstName);
        localStorage.setItem('surname', lastName);
        localStorage.setItem('gender', gender);
        localStorage.setItem('birthday', birthday);
        localStorage.setItem('country', country);
        setLoadingUpating(false);
        setSuccessUpdate(translations.myProfile_updatedWithSuccess);
      })
      .catch(error => {
        console.error('Error creating account:', error);

      });
  };

  return (
    <section className='private-my-profile-wrapper'>

      <div className='private-my-profile-title'>
        <h2>{translations.private_mysettings_aboutMe}</h2>
        {/* <h2>{translations.private_myProfile_mainTitle}</h2> */}
      </div>
      <div className='private-my-profile-wrapper-form'>
        <div className='private-my-profile-form-row-2'>
          <div className='private-my-profile-form-input'>
            <span>{translations.auth_register_firstNameLabel}</span>
            <input type='text' placeholder={translations.auth_register_firstNamePlaceholder} value={firstName} onChange={(e) => { setFirstName(e.target.value); setFirstNameError(false); }} className={firstNameError ? ('auth-register-form-input-input-error') : ('auth-register-form-input-input')} />
          </div>
          <div className='private-my-profile-form-input'>
            <span>{translations.auth_register_lastNameLabel}</span>
            <input type='text' placeholder={translations.auth_register_lastNamePlaceholder} value={lastName} onChange={(e) => { setLastName(e.target.value); setLastNameError(false); }} className={lastNameError ? ('auth-register-form-input-input-error') : ('auth-register-form-input-input')} />
          </div>
        </div>
        <div className='private-my-profile-form-row'>
          <div className="private-my-profile-form-input">
            <span>{translations.auth_register_genderLabel}</span>
            <select value={gender} onChange={(e) => { setGender(e.target.value); setGenderError(false); }} className={genderError ? ('auth-register-form-input-select-error') : ('auth-register-form-input-select')}>
              <option value="" disabled>  {translations.auth_register_genderPlaceholder} </option>
              <option value="man">{translations.auth_register_genderMale}</option>
              <option value="woman">{translations.auth_register_genderFemale}</option>
            </select>
          </div>
        </div>
        <div className='private-my-profile-form-row'>
          <div className='private-my-profile-form-input'>
            <span>{translations.auth_register_birthdayLabel}</span>
            <input type='text' placeholder='dd-mm-yyyy' value={birthday} onChange={handleChangeBirthday} pattern='(?:0[1-9]|[12][0-9]|3[01])-(?:0[1-9]|1[0-2])-(?:19|20)[0-9]{2}' className={birthdayError ? ('auth-register-form-input-input-error') : ('auth-register-form-input-input')} />
          </div>
        </div>
        <div className='private-my-profile-form-row'>
          <div className="private-my-profile-form-input">
            <span>{translations.auth_register_countryLabel}</span>
            <select value={country} onChange={(e) => { setCountry(e.target.value); setCountryError(false); }} className={countryError ? ('auth-register-form-input-select-error') : ('auth-register-form-input-select')}>
              <option value='' disabled selected>{translations.auth_register_countryPlaceholder}</option>
              {countryOptions.map((nationality, index) => (
                <option key={index} value={nationality.value}>
                  {nationality.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      {errorUpdate !== '' && (
        <div className='auth-login-errorMessage'>
          <span>{errorUpdate}</span>
        </div>
      )}

      {successUpdate !== '' && (
        <div className='auth-login-successMessage'>
          <span>{successUpdate}</span>
        </div>
      )}

      <div className='private-my-profile-load-more'>
        <LoadingButton
          buttonText={translations.private_myProfile_updateData}
          actionOnClick={updateUserInformation}
          loading={loadingUpdating}
        />
      </div>
    </section>
  );
}

export default PRIVATE_MY_PROFILE;