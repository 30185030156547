import React, { useState, useContext } from 'react';
import { useAuth } from '../../context/AuthContext';
import { LanguageContext } from '../../context/LanguageContext';
import MainContext from '../../context/MainContext';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import LoadingButton from '../../components/loader';
import '../../style/private/MyProfile.css';

function PRIVATE_MY_PROFILE() {
  const { getSessionInfo } = useAuth();
  const { language } = useContext(LanguageContext);
  const translations = language === 'en' ? enTranslations : ptTranslations;
  const { localStoredEmail, localStoredSession, localStoredProfilePicture, localStoredDisplayName, localStoredUsername } = getSessionInfo();

  const [displayName, setDisplayName] = useState(localStoredDisplayName);
  const [username, setUsername] = useState(localStoredUsername);

  const [loadingPicture, setLoadingPicture] = useState(false);
  const [loadingUsername, setLoadingUsername] = useState(false);

  const fileInputRef = React.createRef();

  const [displayNameError, setDisplayNameError] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  function blobURLtoBlob(blobURL) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';

      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(xhr.response);
        } else {
          reject(new Error(`Failed to convert Blob URL to Blob object. Status: ${xhr.status}`));
        }
      };

      xhr.onerror = () => {
        reject(new Error('Failed to convert Blob URL to Blob object. Network error.'));
      };

      xhr.open('GET', blobURL);
      xhr.send();
    });
  }

  const changeProfilePicture = (event) => {
    const file = event.target.files[0];

    if (file) {
      const fileType = file.type;
      const validImageTypes = ['image/jpeg', 'image/jpg', 'image/png'];

      if (!validImageTypes.includes(fileType)) {
        alert(translations.private_profile_account_somethingWentWrong);
        return;
      }

      const imageUrl = URL.createObjectURL(file);
      setLoadingPicture(true);

      const baseURL = `${MainContext.apiURL}/api/v1/private/users/update/picture`;

      // Convert the Blob URL to a Blob
      blobURLtoBlob(imageUrl)
        .then(blob => {
          // Create FormData and append the Blob
          const formData = new FormData();
          formData.append('image', blob, file.name);

          console.log(blob);

          // Send the FormData in a POST request
          fetch(baseURL, {
            method: 'POST',
            headers: {
              'email': localStoredEmail,
              'username': localStoredUsername,
              'session': localStoredSession
            },
            credentials: 'include',
            body: formData,
          })
            .then(response => {
              if (!response.ok) {
                return Promise.reject({ status: response.status, statusText: response.statusText });
              }
              return response.json();
            })
            .then(data => {
              localStorage.setItem('picture', data.picture);
              window.location.href = '';
            })
            .catch(error => {
              console.error('Error:', error);
            });
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }
  };

  const changesUsername = () => {

    setLoadingUsername(true);

    const baseURL = `${MainContext.apiURL}/api/v1/private/users/update/username`;

    const formData = new URLSearchParams({
      username: localStoredUsername,
      newUsername: username.toLocaleLowerCase(),
      displayName: displayName
    });

    fetch(baseURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'email': localStoredEmail,
        'username': localStoredUsername,
        'session': localStoredSession
      },
      credentials: 'include',
      body: formData.toString(),
    })
      .then(response => {
        if (response.status === 409) {
          setErrorMessage(translations.changeUsername_error_usernameAlreadyTaken);
          return setLoadingUsername(false);
        } else if (!response.ok) {
          setLoadingUsername(false);
          setErrorMessage(translations.changeUsername_error_somethingWentWrong);
          return Promise.reject({ status: response.status, statusText: response.statusText });
        } else {
          localStorage.setItem('username', username.toLocaleLowerCase());
          localStorage.setItem('displayName', displayName);

          window.location.href = `/${language}/${username.toLocaleLowerCase()}`;
          return response.json();
        }
      })
      .then(data => {

      })
      .catch(error => {
        console.error('Error creating account:', error);

      });
  };

  return (
    <section className='private-my-profile-wrapper'>

      <div className='private-my-profile-title'>
        <h2>{translations.private_mysettings_publicInfo}</h2>
      </div>

      <div className='private-my-profile-wrapper-form'>

        <div className='private-my-profile-form-row'>
          <div className='myAccount-settings-profile-container-option-container-input-container'>

            {localStoredProfilePicture ? (
              <img src={`${MainContext.profilePictureURL}${localStoredProfilePicture}`} alt='User Profile' />
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <circle cx="12" cy="12" r="10" stroke="currentColor" stroke-width="1.5" />
                <path d="M7.5 17C9.8317 14.5578 14.1432 14.4428 16.5 17M14.4951 9.5C14.4951 10.8807 13.3742 12 11.9915 12C10.6089 12 9.48797 10.8807 9.48797 9.5C9.48797 8.11929 10.6089 7 11.9915 7C13.3742 7 14.4951 8.11929 14.4951 9.5Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
              </svg>
            )}
            {!loadingPicture ? (
              <input type='file' ref={fileInputRef} className='myAccount-settings-profile-container-option-container-input' onChange={changeProfilePicture} accept='.jpeg, .jpg, .png' />
            ) : (
              <span className="loading-dots">
                {translations.private_profile_socialMedia_holdOn}<span>.</span><span>.</span><span>.</span>
              </span>
            )}
          </div>
        </div>

        <div className='private-my-profile-form-row'>
          <div className='private-my-profile-form-input'>
            <span>{translations.auth_register_displayNameLabel}</span>
            <input type='text' placeholder={translations.auth_register_displayNameLabel} value={displayName} onChange={(e) => { setDisplayName(e.target.value); setDisplayNameError(false); setErrorMessage(''); }} className={displayNameError ? ('auth-register-form-input-input-error') : ('auth-register-form-input-input')} />
          </div>
        </div>
        <div className='private-my-profile-form-row'>
          <div className='private-my-profile-form-input'>
            <span>{translations.auth_register_usernameLabel}</span>
            <input type='text' placeholder={translations.auth_register_usernamePlaceholder} value={username} onChange={(e) => { setUsername(e.target.value); setUsernameError(false); setErrorMessage(''); }} className={usernameError ? ('auth-register-form-input-input-error') : ('auth-register-form-input-input')} />
          </div>
        </div>
      </div>

      {errorMessage !== '' && (
        <div className='auth-login-errorMessage'>
          <span>{errorMessage}</span>
        </div>
      )}


      <div className='private-my-profile-load-more'>
        <LoadingButton
          buttonText={translations.private_myProfile_updateData}
          actionOnClick={changesUsername}
          loading={loadingUsername}
        />
      </div>
    </section>
  );
}

export default PRIVATE_MY_PROFILE;