import { useState, useContext } from 'react';
import { LanguageContext } from '../../context/LanguageContext';
import MainContext from '../../context/MainContext';
import enTranslations from '../../translations/en.json'
import ptTranslations from '../../translations/pt.json'
import LoadingButton from '../../components/loader';
import '../../style/auth/Register.css';

function AUTH_REGISTER_STEP1({ setRecoverStep, email, setEmail }) {
  const [loading, setLoading] = useState(false);
  const { language } = useContext(LanguageContext);
  const translations = language === 'en' ? enTranslations : ptTranslations;

  const [emailError, setEmailError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const validateEmail = (inputEmail) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(inputEmail);
  };

  const sendEmail = () => {

    if (!validateEmail(email)) {
      setErrorMessage(translations.auth_login_emailError);
      setEmailError(true);
      return;
    }

    setLoading(true);
    setErrorMessage('');
    setEmailError(false);

    sendEmailToUser();
  }

  const sendEmailToUser = () => {
    const baseURL = `${MainContext.apiURL}/api/v1/auth/users/recover/send-email`;

    const formData = new URLSearchParams({
      email: email.toLowerCase(),
      language: language
    });

    fetch(baseURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formData.toString(),
    })
      .then(response => {
        if (!response.ok) {
          setLoading(false);
          setErrorMessage(translations.auth_login_errorGenericMessage);
          return Promise.reject({ status: response.status, statusText: response.statusText });
        } else {
          setRecoverStep(2);
          return response.json();
        }
      })
      .then(data => {
      })
      .catch(error => {
        setErrorMessage(translations.auth_login_errorGenericMessage);
        setLoading(false);
      });
  };

  return (
    <section className='auth-register-content-wrapper'>
      <div className='auth-register-content-main-title'>
        <h2>{translations.auth_recoverPassword_mainTitle}</h2>
      </div>

      <div className='auth-register-wrapper-form'>
        <div className='auth-register-form-row'>
          <div className='auth-register-form-input'>
          <span>{translations.auth_login_form_emailLabel}</span>
            <input type='text' placeholder={translations.auth_login_form_emailPlaceholder} required value={email} onChange={(e) => {setEmail(e.target.value.trim()); setEmailError(false); setErrorMessage('');}} className={emailError ? ('auth-register-form-input-input-error') : ('auth-register-form-input-input')} />
          </div>
        </div>
      </div>
      
      {errorMessage !== '' && (
        <div className='auth-login-errorMessage'>
          <span>{errorMessage}</span>
        </div>
      )}

      <div className='auth-register-button'>
        <LoadingButton
          buttonText={translations.auth_recoverPassword_sendEmail}
          loading={loading}
          actionOnClick={sendEmail}
        />
      </div>
    </section>
  );
}

export default AUTH_REGISTER_STEP1;
